import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import React from "react";
import { Button } from "../Button/Button";
import { Label } from "../Label/Label";
import classes from "./ConfirmationDialogComponent.module.scss"
import CloseIcon from '@mui/icons-material/Close';

export type ConfirmationDialogProps = {
    title: string | React.ReactElement;
    message: string | React.ReactElement;
    onConfirm: () => void;
    onCancel?: () => void;
    isOpen: boolean;
    confirmText?: string;
    cancelText?: string;
    showCancel?: boolean;
};

const defaultProps = {
    confirmText: "Ok",
    cancelText: "Cancel",
    showCancel: true,
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
    props: ConfirmationDialogProps
) => {
    const resolvedProps = {
        ...defaultProps,
        ...props,
    };
    return (
        <Dialog
            scroll="paper"
            fullWidth
            maxWidth="xs"
            open={resolvedProps.isOpen}
            disableScrollLock
        >
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        {resolvedProps.title}
                    </Label>
                    {resolvedProps.showCancel && resolvedProps.onCancel ? (
                        <IconButton aria-label="close" onClick={resolvedProps.onCancel}>
                            <CloseIcon />
                        </IconButton>
                    ) :
                        (
                            <IconButton aria-label="close" onClick={resolvedProps.onConfirm}>
                                <CloseIcon />
                            </IconButton>
                        )}
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box className={classes.message_box}>
                    <Label styleName={classes.message}>
                        {resolvedProps.message}
                    </Label>
                </Box>
            </DialogContent>
            <DialogActions className={classes.footer}>
                {resolvedProps.showCancel && resolvedProps.onCancel && (
                    <Button type="secondary" onClick={resolvedProps.onCancel}>
                        {resolvedProps.cancelText}
                    </Button>
                )}
                <Button type="primary" onClick={resolvedProps.onConfirm}>
                    {resolvedProps.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
