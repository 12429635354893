import { Document } from "../../../model/Document";
import { SourceDocument } from "../../../model/SourceDocument";
import { Patient } from "../../../model/Patient";
import { Facility } from "../../../model/Facility";

export class DocumentBuilder {
    public requestDate: string;
    public facilityId: number;
    public facilityName: string;
    public orgId: string;
    public patientId: number;
    public screenDate: string;
    public screeenResult: string = "";
    public id: string = "";
    public sourceDocument: SourceDocument;
    public patient: Patient;
    public facility: Facility;
    public sourceDocumentId: number;

    setRequestDate(date: string) {
        this.requestDate = date;
        return this;
    }

    setFacilityId(id: number) {
        this.facilityId = id;
        return this;
    }

    setFacilityName(name: string) {
        this.facilityName = name;
        return this;
    }

    setOrgId(id: string) {
        this.orgId = id;
        return this;
    }

    setPatientId(id: number) {
        this.patientId = id;
        return this;
    }

    setScreenDate(date: string) {
        this.screenDate = date;
        return this;
    }

    setId(id: string) {
        this.id = id;
        return this;
    }

    setSourceDocument(document: SourceDocument) {
        this.sourceDocument = document;
        return this;
    }

    setPatientInfo(patient: Patient) {
        this.patient = patient;
        return this;
    }

    setFacilityInfo(facility: Facility) {
        this.facility = facility;
        return this;
    }

    setSourceDocumentId(id: number) {
        this.sourceDocumentId = id;
        return this;
    }

    build() {
        let document = { ...this.sourceDocument.documentTemplate } as Document;

        document.facilityId = this.facilityId;
        document.facilityName = this.facilityName;
        document.orgId = this.orgId;
        document.patientId = this.patientId;
        document.facility = this.facility;
        document.patient = this.patient;

        document.requestedDate = this.requestDate;
        document.screenDate = this.screenDate;
        document.id = this.id;
        document.sourceDocumentId = this.sourceDocumentId;

        return document;
    }
}