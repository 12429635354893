import React from "react";
import { SectionEditorComponent } from "../sectionEditorComponent";
import { IsSection, IsTotal, Document } from "../../../../../../../model/Document";
import { SourceDocument } from "../../../../../../../model/SourceDocument";
import { Total } from "../../../../../../../model/Total";
import { Section } from "../../../../../../../model/Section";
import { Question } from "../../../../../../../model/Question";
import { TotalsComponent } from "../../../../../../totals/totalsComponent";
import { EditorRowComponent } from "../../EditorRowComponent";
import { QuestionComponent } from "../../../../../../questions/questionComponent";
import { EditRowFieldsComponent } from "../../EditRowFieldsComponent";

export type SectionPartPropsType = {
    part: Section | Question | Total;
    parent: Section | Total;
    sourceDocument: SourceDocument;
    level: number,
    parentsList: (Section | Total)[];
    canMoveUp: boolean;
    canMoveDown: boolean;
    addItem: (addAnchor: Question | Section | Total) => void;
};

export const SectionPartEditorComponent: React.FC<SectionPartPropsType> = (
    props: SectionPartPropsType
) => {
    if (IsSection(props.part)) {
        return <SectionEditorComponent section={props.part} parent={props.parent} level={props.level} parentsList={props.parentsList} sourceDocument={props.sourceDocument} canMoveUp={props.canMoveUp} canMoveDown={props.canMoveDown} addItem={props.addItem} />;
    }
    if (IsTotal(props.part)) {
        // TODO: this doesn't support having children under totals types. There is only one document which has this and I'm not sure why it was done that way
        return <EditorRowComponent row={props.part} level={props.level}
            QuestionComponent={<TotalsComponent totalSection={props.part} document={{ screenResultJSON: "" } as Document} />}
            EditComponent={<EditRowFieldsComponent part={props.part} parent={props.parent} parentsList={props.parentsList} sourceDocument={props.sourceDocument} />}
            canMoveUp={props.canMoveUp}
            canMoveDown={props.canMoveDown}
            docInUse={props.sourceDocument.isInUse}
            docIsGlobal={props.sourceDocument.isGlobal}
            addItem={props.addItem} />;
    }
    return <EditorRowComponent row={props.part} level={props.level}
        QuestionComponent={<QuestionComponent question={props.part} answerCategories={props.sourceDocument.documentTemplate.answerCategories} />}
        EditComponent={<EditRowFieldsComponent part={props.part} parent={props.parent} parentsList={props.parentsList} sourceDocument={props.sourceDocument} />}
        canMoveUp={props.canMoveUp}
        canMoveDown={props.canMoveDown}
        docInUse={props.sourceDocument.isInUse}
        docIsGlobal={props.sourceDocument.isGlobal}
        addItem={props.addItem} />;

};
