import { Document } from "../../model/Document";
import { Total } from "../../model/Total";
import React from "react";
import { useTotal } from "../../hooks/useTotal";
import { TotalScoreComponent } from "./documentTotal/TotalScoreComponent";
import { SectionTotalComponent } from "./sectionTotal/sectionTotalComponent";
import { SubheaderTotalComponent } from "./subheaderTotal/subheaderTotalComponent";
import { PDPMTotalScoreComponent } from "./documentPDPMTotal/PDPMTotalScoreComponent";

export type TotalsPropsType = {
    totalSection: Total;
    document: Document;
};

export const TotalsComponent: React.FC<TotalsPropsType> = (
    props: TotalsPropsType
) => {
    const score = useTotal(props.totalSection.parentId);
    const optionalScore = useTotal(props.totalSection.parentId, "Optional");
    //const requiredScore = useTotal(props.totalSection.ParentID,"Required");

    switch (props.totalSection.kind) {
        case "totalScore":
            return <TotalScoreComponent score={score} totalSection={props.totalSection} document={props.document} />;
        case "optionalScore":
            return <TotalScoreComponent score={optionalScore} totalSection={props.totalSection} document={props.document} />;
        case "requiredScore":
            return <TotalScoreComponent score={score - optionalScore} totalSection={props.totalSection} document={props.document} />;
        case "PDPMScore":
            return <PDPMTotalScoreComponent totalSection={props.totalSection} pdpmscores={props.document.screenResultJSON} />;
        case "SCORE_SUBHEADER":
            return (
                <SubheaderTotalComponent
                    score={score}
                    subheaderTitle={props.totalSection.question}
                />
            );
        case "sectiontotal":
            return <SectionTotalComponent score={score} />;
        default:
            return <></>;
    }
};
