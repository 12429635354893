
import { TextField } from "@mui/material";
import { DatePicker as MuiDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import classes from "./DatePicker.module.scss";
import { ReactComponent as calendar3 } from "bootstrap-icons/icons/calendar3.svg"
import { ReactComponent as chevronLeft } from "bootstrap-icons/icons/chevron-left.svg"
import { ReactComponent as chevronRight } from "bootstrap-icons/icons/chevron-right.svg"
import { useState, useMemo } from "react";
import { DateValidationError } from "@mui/x-date-pickers/internals";

export type CustomerDatePickerProps = {
    dateValue;
    onValueChanged;
    label?;
    required?;
    minDate?: string | number | Date | Dayjs;
    maxDate?: string | number | Date | Dayjs;
    minDateErrorMsg?: string;
    maxDateErrorMsg?: string;
}

export const CustomDatePicker: React.FC<CustomerDatePickerProps> = (props: CustomerDatePickerProps) => {
    const [error, setError] = useState<DateValidationError | null>(null);
  
    const errorMessage = useMemo(() => {
      switch (error) {
        case 'maxDate': {
            return props.maxDateErrorMsg;
          }
        case 'minDate': {
          return props.minDateErrorMsg;
        }
  
        default: {
          return null;
        }
      }
    }, [error]);
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDatePicker
                label={props.label}
                value={props.dateValue}
                onChange={props.onValueChanged}
                minDate={props.minDate ? dayjs(props.minDate) : undefined}
                maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
                className={classes.inputField}
                components={{ OpenPickerIcon: calendar3, LeftArrowIcon: chevronLeft, RightArrowIcon: chevronRight }}
                toolbarPlaceholder="MM/DD/YYY"
                InputProps={{ classes: { input: classes.inputBox }, placeholder: "MM/DD/YYY" }}
                PopperProps={{ className: classes.calender }}
                renderInput={(inputProps) =>
                    <TextField
                        {...inputProps}
                        required={props.required}
                        error={!props.minDateErrorMsg && !props.maxDateErrorMsg ? false : !!errorMessage}
                        helperText={errorMessage} />}
                showDaysOutsideCurrentMonth
                views={["year", "month", "day"]}
                onError={(err) => setError(err)}
            />
        </LocalizationProvider>
    );
};