
import { Document } from "../../../model/Document";
import { SourceDocument } from "../../../model/SourceDocument";
import React from "react";
import { areAllFieldsBlank } from "../../../store/slices/documentSlice";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../common/Dropdown/DropdownOption/DropdownOption";
import classes from './DocumentScreenTableScreenReasonItem.module.scss';
import { Label } from "../../common/Label/Label";

type Props = {
    document: Document,
    sourceDocuments: SourceDocument[],
    setChangePending,
    selectedSource,
    setSelectedSource,
    initialScreenReason,
    canEdit: boolean
};

export const DocumentScreenTableScreenReasonItem: React.FC<Props> = (props: Props) => {
    function onSelectionChanged(document: string) {
        props.setSelectedSource(document);

        if (document === props.initialScreenReason) {
            props.setChangePending(false);
        }
        else {
            props.setChangePending(true);
        }
    }

    return (
        areAllFieldsBlank(props.document) && props.canEdit ?
            (
                <Dropdown value={props.selectedSource} onChange={(e) => onSelectionChanged(e.target.value)}
                    disableUnderline
                    variant="standard"
                    fullWidth
                    sx={{ width: 110 }}
                >
                    {props.sourceDocuments.map((row) => {
                        return (
                            <DropdownOption className={classes.text} key={row.id} value={row.documentTemplate.screenReason}>{row.documentTemplate.screenReason}</DropdownOption>
                        )
                    })}
                </Dropdown>
            )
            : <Label>{props.document?.screenReason}</Label>
    )
}