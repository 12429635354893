export const persistToken = (token: string) => {
    window.localStorage.setItem('jwt_token', token)
}

export const getAuthToken = (): string => {
    return window.localStorage.getItem('jwt_token')
}

export const checkTokenValidity = (token: string): boolean => {
    try {
        const decodedToken = JSON.parse(window.atob(token.split('.')[1]));
        return decodedToken.exp * 1000 >= new Date().getTime()
    } catch {
        return false;
    }
}