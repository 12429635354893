import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import ApplicationComponent from './components/ApplicationComponent';
import { PrintComponent } from "./components/mainPage/print/PrintComponent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MaintenanceComponent } from './components/maintenancePage/maintenance/MaintenanceComponent';

import store from './store/configureStore';

import '../src/assests/fonts/opensans-regular-webfont.woff2';
import '../src/assests/fonts/opensans-bold-webfont.woff2';
import '../src/assests/fonts/opensans-italic-webfont.woff2';
import '../src/assests/fonts/opensans-semibold-webfont.woff2';
import './styles/_global.scss';

import classes from './index.module.scss';

import { SnackbarProvider } from 'notistack';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <StyledEngineProvider injectFirst>
        <SnackbarProvider className={classes.snackbar}>
            <Provider store={store}>
                <Router>
                    <Routes>
                        <Route path="/" element={<ApplicationComponent />} />
                        <Route path="/preview" element={<PrintComponent />} />
                        <Route path="/maintenance" element={<MaintenanceComponent />} />
                    </Routes>
                </Router>
            </Provider>
        </SnackbarProvider>
    </StyledEngineProvider>
);
