import React, { useState } from "react";
import classes from "./DocSignerComponent.module.scss";
import { Label } from "../../../common/Label/Label";
import { TextInput } from "../../../common/TextInput/TextInput";
import { useAppDispatch } from "../../../../store/hooks";
import { Box } from "@mui/material";
import { updateCompletingUser } from "../../../../store/slices/documentSlice";

import AppContext from "../../../global";

type Props = { completingUser: string };


export const DocSignerComponent: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const [textFieldValue, setTextFieldValue] = useState(props?.completingUser ?? "");

    function handleOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        dispatch(
            updateCompletingUser({
                completingUser: textFieldValue
            })
        );
    }

    return (
        <>
            <div className={classes.scoreGroup}>
                <Label styleName={classes["title"]}>Signature</Label>
            </div>
            <Box className={classes.container}>
                <div className={classes.editorDiv}>
                    <Label styleName={classes.label}>User completing screen*</Label>

                    {AppContext.isInPrintPreview ? (
                        <Label styleName={classes.print_label}>{textFieldValue}</Label>
                    ) : (
                        <TextInput
                            className={classes.inputField}
                            aria-required
                            required={true}
                            onChange={(e) => setTextFieldValue(e.target.value)}
                            onBlur={handleOnBlur}
                            defaultValue={textFieldValue}
                        />
                    )}
                </div>
            </Box>
        </>
    );
}
