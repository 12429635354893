import {
    Document, IsQuestion,
    IsSection, IsTotal
} from "../../model/Document";
import { RequiredTotal } from "../../model/RequiredTotal";
import { OptionalTotal } from "../../model/OptionalTotal";
import { DocumentTotal } from "../../model/DocumentTotal";
import { SubheaderTotal } from "../../model/SubheaderTotal";
import { SectionTotal } from "../../model/SectionTotal";
import { CommentQuestion } from "../../model/CommentQuestion";
import { AccessRight } from "../../model/AccessRight";
import { ResultDefinition } from "../../model/ResultDefinition";
import { SourceDocument } from "../../model/SourceDocument";
import { CheckQuestion } from "../../model/CheckQuestion";
import { DateQuestion } from "../../model/DateQuestion";
import { TextQuestion } from "../../model/TextQuestion";
import { LargeTextQuestion } from "../../model/LargeTextQuestion";
import { NumericQuestion } from "../../model/NumericQuestion";
import { MultiselectQuestion } from "../../model/MultiselectQuestion";
import { SingleselectQuestion } from "../../model/SingleselectQuestion";
import { AnswerCategory } from "../../model/AnswerCategory";
import { PhoneQuestion } from "../../model/PhoneQuestion";
import { SelectQuestionOption } from "../../model/SelectQuestionOption";
import { Total } from "../../model/Total";
import { Section } from "../../model/Section";
import { Question } from "../../model/Question";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { getScore } from "../../components/helpers/helperMethods";
import { NumericQuestionOption } from "../../model/NumericQuestionOption";

export interface UpdateKindState {
    id: number;
    kind: string;
}

export interface UpdateTitleState {
    id: number;
    title: string;
}

export interface UpdateCaptionState {
    id: number;
    caption: string;
}

export interface UpdateParentState {
    id: number;
    originalParentID: number;
    newParentID: number;
}

export interface UpdatePositionState {
    id: number;
    MoveDirection: "Up" | "Down";
}

export interface AddItemState {
    parentID: number;
    kind: string;
    formatType: string;
}

export interface DeleteItemState {
    id: number;
}

export interface UpdateQuestionState {
    id: number;
    question: string;
}

export interface UpdateRequiredState {
    id: number;
    required: boolean;
}

export interface UpdateShowScoreState {
    id: number;
    showScore: boolean;
}

export interface UpdateShowNotesState {
    id: number;
    showNotes: boolean;
}

export interface UpdateNoteRequiredState {
    id: number;
    noteRequired: boolean;
}

export interface UpdateActiveState {
    id: number;
    active: boolean;
}

export interface UpdateCommentState {
    id: number;
    comment: "allowed" | "required" | "notAllowed";
}

export interface UpdateMappingState {
    id: number;
    Mapping: string;
}

export interface UpdateFormatTypeState {
    id: number;
    formatType: string;
}

export interface UpdateOptionsState {
    id: number;
    options: SelectQuestionOption[] | NumericQuestionOption[];
    answerCategory: string;
}

export interface UpdatePromptState {
    id: number;
    prompt: string;
}

export interface UpdatePartsState {
    id: number;
    parts: Array<Question | Section | Total>;
}

export interface UpdateSourceDocumentIdState {
    sourceDocumentId: number;
}

export interface UpdateScreenReasonState {
    id: number;
    screenReason: string;
}

export interface UpdateSectionsState {
    id: number;
    sections: (Section | Question | Total)[];
}

export interface UpdateAllowedDisciplinesState {
    id: number;
    allowedDisciplines: string[];
}

export interface UpdateAccessRightsState {
    id: number;
    accessRights: AccessRight;
}

export interface UpdateOrgIdState {
    id: number;
    orgId: string;
}

export interface UpdateEffectiveFromState {
    id: number;
    effectiveFrom: string;
}

export interface UpdateEffectiveThruState {
    id: number;
    effectiveThru: string;
}

export interface UpdateResultDefinitionsState {
    resultDefinitions: ResultDefinition[];
}

export interface UpdateResultDefinitionState {
    resultDefinition: ResultDefinition;
}

export interface UpdateAnswerCategoriesState {
    answerCategories: AnswerCategory[];
}

export interface UpdateAnswerCategoryState {
    answerCategory: AnswerCategory;
}

export interface UpdateSelectQuestionOptionState {
    answerCategoryId: number;
    selectQuestionOption: SelectQuestionOption | NumericQuestionOption;
}

export interface UpdateNumericRangeState {
    id: number;
    min?: number;
    max?: number;
    answerCategory: string;
    options: NumericQuestionOption[];
}

const initialState = {} as SourceDocument;

export const sourceDocumentSlice = createSlice({
    name: "updateSourceDocument",
    initialState,
    reducers: {
        updateKind: (state, action: PayloadAction<UpdateKindState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id);
            if (!questionState) {
                return;
            }
            questionState.kind = action.payload.kind;
        },

        updateTitle: (state, action: PayloadAction<UpdateTitleState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section;
            if (!questionState) {
                return;
            }
            questionState.title = action.payload.title;
        },

        updateCaption: (state, action: PayloadAction<UpdateCaptionState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section;
            if (!questionState) {
                return;
            }
            questionState.caption = action.payload.caption;
        },

        updateParent: (state, action: PayloadAction<UpdateParentState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id);
            let ogParentState = getItemState(state.documentTemplate, action.payload.originalParentID) as Section | Total;
            let newParentState = getItemState(state.documentTemplate, action.payload.newParentID) as Section | Total;

            if (action.payload.originalParentID === action.payload.newParentID
                || !questionState
                || (!newParentState && action.payload.newParentID !== -1)) {
                return;
            }

            if (action.payload.originalParentID === -1) {
                let ind = state.documentTemplate.sections?.findIndex(item => item.id === questionState.id);
                if (ind >= 0) {
                    state.documentTemplate.sections.splice(ind, 1);
                }
            } else {
                let ind = ogParentState?.parts?.findIndex(item => item.id === questionState.id);
                if (ind >= 0) {
                    ogParentState.parts.splice(ind, 1);
                }
            }

            if (action.payload.newParentID === -1) {
                state.documentTemplate.sections.push(questionState);
            } else {
                newParentState.parts.push(questionState);
            }
        },

        updatePosition: (state, action: PayloadAction<UpdatePositionState>) => {
            let { foundQuestion, foundParent } = getItemParentState(state.documentTemplate, action.payload.id);
            let parts: (Question | Section | Total)[];
            if (foundParent.id === -1) {
                parts = state.documentTemplate.sections;
            } else {
                parts = foundParent?.parts;
            }

            if (parts?.length > 0) {
                let shift = action.payload.MoveDirection === "Up" ? -1 : 1;
                let curIdx = parts.findIndex(item => item.id === foundQuestion.id);

                if (curIdx + shift >= 0 && curIdx + shift <= parts.length - 1) {
                    parts.splice(curIdx, 1);
                    parts.splice(curIdx + shift, 0, foundQuestion);
                }
            }
        },

        addItem: (state, action: PayloadAction<AddItemState>) => {
            let parentState = getItemState(state.documentTemplate, action.payload.parentID) as Section | Total;
            let newID = getNewItemID(state.documentTemplate);
            let newItem: Question | Section | Total;
            switch (action.payload.kind) {
                case "LARGE_TEXT":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as LargeTextQuestion;
                    break;
                case "TEXT":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as TextQuestion;
                    break;
                case "numeric":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType, options: [] } as NumericQuestion;
                    break;
                case "DATE":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as DateQuestion;
                    break;
                case "check":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as CheckQuestion;
                    break;
                case "COMMENT":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as CommentQuestion;
                    break;
                case "multiselect":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType, options: [] } as MultiselectQuestion;
                    break;
                case "select":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType, options: [] } as SingleselectQuestion;
                    break;
                case "section":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType, parts: [] } as Section;
                    break;
                case "sectiontotal":
                    newItem = { id: newID, kind: action.payload.kind, active: true, parts: [] } as SectionTotal;
                    break;
                case "SCORE_SUBHEADER":
                    newItem = { id: newID, kind: action.payload.kind, active: true, parts: [] } as SubheaderTotal;
                    break;
                case "totalScore":
                    newItem = { id: newID, kind: action.payload.kind, active: true, parts: [], parentId: action.payload.parentID } as DocumentTotal;
                    break;
                case "optionalScore":
                    newItem = { id: newID, kind: action.payload.kind, active: true, parts: [], parentId: action.payload.parentID } as OptionalTotal;
                    break;
                case "requiredScore":
                    newItem = { id: newID, kind: action.payload.kind, active: true, parts: [], parentId: action.payload.parentID } as RequiredTotal;
                    break;
                case "PHONE_NUMBER":
                    newItem = { id: newID, kind: action.payload.kind, active: true, formatType: action.payload.formatType } as PhoneQuestion;
                    break;

                default:
                    break;
            }

            parentState.parts.splice(0, 0, newItem);

        },

        deleteItem: (state, action: PayloadAction<DeleteItemState>) => {
            let { foundQuestion, foundParent } = getItemParentState(state.documentTemplate, action.payload.id);
            let parts: (Question | Section | Total)[];
            if (foundParent.id === -1) {
                parts = state.documentTemplate.sections;
            } else {
                parts = foundParent?.parts;
            }

            if (parts?.length > 0) {
                let curIdx = parts.findIndex(item => item.id === foundQuestion.id);

                if (curIdx >= 0) {
                    parts.splice(curIdx, 1);
                }
            }
        },

        updateQuestion: (state, action: PayloadAction<UpdateQuestionState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question;
            if (!questionState) {
                return;
            }
            questionState.question = action.payload.question;
        },

        updateRequired: (state, action: PayloadAction<UpdateRequiredState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question | Total;
            if (!questionState) {
                return;
            }
            questionState.required = action.payload.required;
        },

        updateShowScore: (state, action: PayloadAction<UpdateShowScoreState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question | Total;
            if (!questionState) {
                return;
            }
            questionState.showScore = action.payload.showScore;
        },

        updateShowNotes: (state, action: PayloadAction<UpdateShowNotesState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question | Total;
            if (!questionState) {
                return;
            }
            questionState.showNotes = action.payload.showNotes;
        },

        updateNoteRequired: (state, action: PayloadAction<UpdateNoteRequiredState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question;
            if (!questionState) {
                return;
            }
            questionState.noteRequired = action.payload.noteRequired;
        },

        updateActive: (state, action: PayloadAction<UpdateActiveState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question | Total;
            if (!questionState) {
                return;
            }
            questionState.active = action.payload.active;
        },

        updateComment: (state, action: PayloadAction<UpdateCommentState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question;
            if (!questionState) {
                return;
            }
            questionState.comment = action.payload.comment;
        },

        updateMapping: (state, action: PayloadAction<UpdateMappingState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Question | Total;
            if (!questionState) {
                return;
            }
            questionState.mapping = action.payload.Mapping;
        },

        updateFormatType: (state, action: PayloadAction<UpdateFormatTypeState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section | Question;
            if (!questionState) {
                return;
            }
            questionState.formatType = action.payload.formatType;
        },

        updateOptions: (state, action: PayloadAction<UpdateOptionsState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as SingleselectQuestion;
            if (!questionState) {
                return;
            }
            questionState.options = action.payload.options;
            questionState.answerCategory = action.payload.answerCategory;
        },

        updatePrompt: (state, action: PayloadAction<UpdatePromptState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as LargeTextQuestion;
            if (!questionState) {
                return;
            }
            questionState.prompt = action.payload.prompt;
        },

        updateParts: (state, action: PayloadAction<UpdatePartsState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section | Total;
            if (!questionState) {
                return;
            }
            questionState.parts = action.payload.parts;
        },

        updateScreenId: (state, action: PayloadAction<UpdateSourceDocumentIdState>) => {
            state.documentTemplate.sourceDocumentId = action.payload.sourceDocumentId;
        },

        updateScreenReason: (state, action: PayloadAction<UpdateScreenReasonState>) => {
            state.documentTemplate.screenReason = action.payload.screenReason;
        },

        updateSections: (state, action: PayloadAction<UpdateSectionsState>) => {
            state.documentTemplate.sections = action.payload.sections;
        },

        updateResultDefinitions: (state, action: PayloadAction<UpdateResultDefinitionsState>) => {
            state.documentTemplate.resultDefinitions = action.payload.resultDefinitions;
        },

        updateResultDefinition: (state, action: PayloadAction<UpdateResultDefinitionState>) => {
            let index = state.documentTemplate.resultDefinitions.findIndex(c => c.resultTypeId == action.payload.resultDefinition.resultTypeId);
            state.documentTemplate.resultDefinitions[index] = action.payload.resultDefinition;
        },

        addResultDefinition: (state, action: PayloadAction<UpdateResultDefinitionState>) => {
            if (state.documentTemplate.resultDefinitions) {
                state.documentTemplate.resultDefinitions.push(action.payload.resultDefinition);
            } else {
                state.documentTemplate.resultDefinitions = [action.payload.resultDefinition]
            }
        },

        removeResultDefinition: (state, action: PayloadAction<UpdateResultDefinitionState>) => {
            state.documentTemplate.resultDefinitions = state.documentTemplate.resultDefinitions.filter(c => c.resultTypeId !== action.payload.resultDefinition.resultTypeId);
        },

        updateAnswerCategories: (state, action: PayloadAction<UpdateAnswerCategoriesState>) => {
            state.documentTemplate.answerCategories = action.payload.answerCategories;
        },

        updateAnswerCategory: (state, action: PayloadAction<UpdateAnswerCategoryState>) => {
            let index = state.documentTemplate.answerCategories.findIndex(c => c.answerCategoryId === action.payload.answerCategory.answerCategoryId);
            state.documentTemplate.answerCategories[index] = action.payload.answerCategory;
        },

        addAnswerCategory: (state, action: PayloadAction<UpdateAnswerCategoryState>) => {
            if (state.documentTemplate.answerCategories) {
                state.documentTemplate.answerCategories.push(action.payload.answerCategory);
            } else {
                state.documentTemplate.answerCategories = [action.payload.answerCategory]
            }
        },

        updateSelectQuestionOption: (state, action: PayloadAction<UpdateSelectQuestionOptionState>) => {
            let answerCategoryIx = state.documentTemplate.answerCategories.findIndex(cat => cat.answerCategoryId === action.payload.answerCategoryId);
            let optionIx = state.documentTemplate.answerCategories[answerCategoryIx].options.findIndex(opt => opt.optionId === action.payload.selectQuestionOption.optionId);
            state.documentTemplate.answerCategories[answerCategoryIx].options[optionIx] = action.payload.selectQuestionOption;
        },

        addSelectQuestionOption: (state, action: PayloadAction<UpdateSelectQuestionOptionState>) => {
            let answerCategoryIx = state.documentTemplate.answerCategories.findIndex(cat => cat.answerCategoryId === action.payload.answerCategoryId);
            state.documentTemplate.answerCategories[answerCategoryIx].options.push(action.payload.selectQuestionOption);
        },

        removeSelectQuestionOption: (state, action: PayloadAction<UpdateSelectQuestionOptionState>) => {
            let answerCategory = state.documentTemplate.answerCategories.find(cat => cat.answerCategoryId === action.payload.answerCategoryId);
            if (answerCategory.answerCategoryType === "VALUE")
            {
                answerCategory.options = (answerCategory.options as SelectQuestionOption[]).filter(opt => opt.optionId !== action.payload.selectQuestionOption.optionId);
            }
            else if (answerCategory.answerCategoryType === "RANGE") 
            {
                answerCategory.options = (answerCategory.options as NumericQuestionOption[]).filter(opt => opt.optionId !== action.payload.selectQuestionOption.optionId);
            }
            // else do nothing (?) 
        },

        updateAllowedDisciplines: (state, action: PayloadAction<UpdateAllowedDisciplinesState>) => {
            state.documentTemplate.allowedDisciplines = action.payload.allowedDisciplines;
        },

        updateAccessRights: (state, action: PayloadAction<UpdateAccessRightsState>) => {
            state.documentTemplate.accessRights = action.payload.accessRights;
        },

        updateOrgId: (state, action: PayloadAction<UpdateOrgIdState>) => {
            state.documentTemplate.orgId = action.payload.orgId;
        },

        updateEffectiveFrom: (state, action: PayloadAction<UpdateEffectiveFromState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section;
            if (!questionState) {
                return;
            }
            questionState.effectiveFrom = action.payload.effectiveFrom;
        },

        updateEffectiveThru: (state, action: PayloadAction<UpdateEffectiveThruState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as Section;
            if (!questionState) {
                return;
            }
            questionState.effectiveThru = action.payload.effectiveThru;
        },

        updateSourceDocument: (state, action: PayloadAction<SourceDocument>) => {
            return action.payload;
        },

        updateNumericRange: (state, action: PayloadAction<UpdateNumericRangeState>) => {
            let questionState = getItemState(state.documentTemplate, action.payload.id) as NumericQuestion;
            if (!questionState) {
                return;
            }
            questionState.min = action.payload.min;
            questionState.max = action.payload.max;
            questionState.answerCategory = action.payload.answerCategory;
        }
    }
});

export function getParentList(document: Document): (Section | Total)[] {
    let parents: (Section | Total)[] = [{ id: -1, title: "document root", kind: "section", caption: null, formatType: null, disciplines: null }];

    const traverseSection = (item: Section | Total) => {
        if (item?.parts) {
            for (let part of item.parts) {
                if (IsSection(part)) {
                    parents.push(part);
                    traverseSection(part as Section | Total);
                }
            }
        }
    };

    traverseSection({ parts: document?.sections } as Section);
    return parents;
}

function getItemState(
    document: Document,
    id: number
): Question | Section | Total | undefined {
    let foundQuestion: Question | Section | Total | undefined;
    const traverseSections = (section: Section | Total, id: number) => {
        if (foundQuestion) {
            return;
        }
        if (section?.parts) {
            for (let part of section.parts) {
                if (part.id === id) {
                    foundQuestion = part;
                } else {
                    traverseSections(part, id);
                }
            }
        }
    };

    traverseSections({ parts: document.sections } as Section, id);
    return foundQuestion;
}

function getItemParentState(
    document: Document,
    id: number
): { foundQuestion: Question | Section | Total | undefined, foundParent: Section | Total } {
    let foundQuestion: Question | Section | Total | undefined;
    let foundParent: Section | Total | undefined;
    const traverseSections = (section: Section | Total, id: number) => {
        if (foundQuestion) {
            return;
        }
        if (section?.parts) {
            for (let part of section.parts) {
                if (part.id === id) {
                    foundQuestion = part;
                    foundParent = section;
                } else if (IsSection(part) || IsTotal(part)) {
                    traverseSections(part, id);
                }
            }
        }
    };
    traverseSections({ parts: document.sections, id: -1 } as Section, id);
    return { foundQuestion, foundParent };
}

function getNewItemID(document: Document): number {
    let newID = 1;

    const traverseSections = (section: Section | Total) => {
        if (section.id >= newID) {
            newID = section.id + 1;
            return;
        }
        if (section?.parts) {
            for (let part of section.parts) {
                if (part.id >= newID) {
                    newID = part.id + 1;
                }
                if (IsSection(part) || IsTotal(part)) {
                    traverseSections(part);
                }
            }
        }
    };
    traverseSections({ parts: document.sections, id: -1 } as Section);

    return newID;
}

export const { updateSourceDocument, updateKind, updateTitle, updateCaption,
    updateParent, updatePosition, addItem, deleteItem,
    updateQuestion, updateRequired, updateShowScore, updateShowNotes, updateNoteRequired, updateActive, updateComment,
    updateMapping, updateFormatType, updateOptions, updatePrompt, updateParts, updateScreenId, updateScreenReason,
    updateSections, updateResultDefinitions, updateAnswerCategories, updateAllowedDisciplines, updateAccessRights, updateOrgId,
    updateEffectiveFrom, updateEffectiveThru, updateResultDefinition, addResultDefinition, removeResultDefinition,
    updateSelectQuestionOption, addSelectQuestionOption, removeSelectQuestionOption, updateAnswerCategory, addAnswerCategory, updateNumericRange } =
    sourceDocumentSlice.actions;
export default sourceDocumentSlice.reducer;
