import { useMemo } from "react";
import classes from "./Button.module.scss";
import MuiButton from "@mui/material/Button";

export type ButtonProps = {
    onClick?: () => void;
    type?: "primary" | "secondary" | "danger";
    styles?: React.CSSProperties;
    disabled?: boolean
};

const defaultProps: ButtonProps = {
    onClick: () => { },
    styles: {},
    type: "primary",
    disabled: false
};

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (
    props: React.PropsWithChildren<ButtonProps>
) => {
    const propsToUse = { ...defaultProps, ...props };
    const classesToUse = useMemo(() => {
        return [classes.root, classes[propsToUse.type]].join(" ");
    }, [propsToUse]);

    return (
        <MuiButton className={classesToUse} onClick={propsToUse.onClick} disabled={propsToUse.disabled}>
            {propsToUse.children}
        </MuiButton>
    );
};
