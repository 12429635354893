import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IsSection, Document } from "../../../../../model/Document";
import { SourceDocument } from "../../../../../model/SourceDocument";
import { Total } from "../../../../../model/Total";
import { Section } from "../../../../../model/Section";
import { Question } from "../../../../../model/Question";
import classes from "./EditScreenPartsEditorComponent.module.scss";
import { SectionEditorComponent } from "./sectionEditor/sectionEditorComponent";
import { sourceDocumentSelector } from "../../../../../store/selectors/sourceDocumentSelectors";
import { addItem, getParentList, updateSourceDocument } from "../../../../../store/slices/sourceDocumentSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { AddPartDialog as AddPartDialog } from "./addPartDialog/AddPartDialog";

type Props = {
    sourceDocument?: SourceDocument
}

export const EditScreenPartsEditorComponent: React.FC<Props> = (props) => {
    const [addPartInitialParent, setAddPartInitialParent] = useState<Section | Total>(null);
    const [isAddPartDialogOpen, setIsAddPartDialogOpen] = useState(false);

    const dispatch = useAppDispatch();

    let sourceDocument = useAppSelector(sourceDocumentSelector);
    var parentList: (Section | Total)[] = getParentList(sourceDocument?.documentTemplate);

    function handleAddPart(addAnchor: Section | Total) {
        setAddPartInitialParent(addAnchor);
        setIsAddPartDialogOpen(true);
    }

    function handleCreatePart(parentID: number, kind: string, formatType: string) {
        dispatch(addItem({ parentID: parentID, kind: kind, formatType: formatType }));
        setIsAddPartDialogOpen(false);
    }

    function handleCancelAddPart() {
        setIsAddPartDialogOpen(false);
    }

    return (
        <>
            <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table}
                    aria-label="customized table">
                    <TableHead>
                        <TableRow className={classes.table_head}>
                            {/* <TableCell className={classes.cell}></TableCell> */}
                            <TableCell className={classes.cell}></TableCell>
                            <TableCell className={classes.cell}></TableCell>
                            <TableCell className={classes.cell}></TableCell>
                            <TableCell className={classes.fillCell}>Form Questions</TableCell>
                            <TableCell className={classes.cell}>Active</TableCell>
                            <TableCell className={classes.cell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.table_row}>
                        {sourceDocument?.documentTemplate?.sections?.map((row, ix) => {
                            return (
                                IsSection(row) ? (
                                    <SectionEditorComponent key={row.id} section={row} level={1} parent={{ id: -1, caption: "document root", kind: "section", title: null, formatType: null, disciplines: null }} parentsList={parentList} sourceDocument={sourceDocument}
                                        canMoveUp={ix !== 0}
                                        canMoveDown={ix !== sourceDocument.documentTemplate.sections.length - 1}
                                        addItem={handleAddPart} />
                                ) : null
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            <AddPartDialog
                key={`addPartDialog_${addPartInitialParent?.id}`}
                parent={addPartInitialParent}
                parentsList={parentList}
                isOpen={isAddPartDialogOpen}
                handleCreatePart={handleCreatePart}
                handleCancel={handleCancelAddPart}
            />
        </>
    );
};
