import Box from "@mui/material/Box";
import { DocumentHeader } from "./DocumentHeader/DocumentHeader";
import { DocumentBodyComponent } from "./DocumentBodyComponent"
import { Document } from "../../../model/Document";

import classes from "./DocumentComponent.module.scss"

export type DocumentDialogProps = {
    document: Document;
    patientName: string;
    setDiscipline?;
    discipline;
};

export const DocumentComponent: React.FC<DocumentDialogProps> = (props) => {
    return (
        <Box className={classes.container}>
            <DocumentHeader
                onSelectDiscipline={(selectedDiscipline: string) => {
                    props.setDiscipline(selectedDiscipline);
                }}
                patientName={props.patientName}
                discipline={props.discipline}
            />

            <DocumentBodyComponent document={props.document} />
        </Box>
    );
};