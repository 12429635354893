import * as api from "./documentRepository";
import store from "../../store/configureStore"
import { Document } from "../../model/Document";

export async function UpdateDocument() {
    await api.UpdateDocument(store.getState()?.updateDocument);
}

export async function DeleteDocument(documentId: string) {
    await api.DeleteDocument(documentId);
}

export async function CreateDocument() {
    await api.SaveDocument(store.getState()?.updateDocument);
}

export async function GetAllDocumentsByPatientId(patientId: string, facilityId: string, orgId: string): Promise<Document[]> {
    return await api.GetAllDocumentsByPatientId(patientId, facilityId, orgId);
}