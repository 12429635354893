import React from "react";
import { Label } from "../../common/Label/Label";
import classes from "./TotalScoreComponent.module.scss"
import { Document } from "../../../model/Document";
import { Total } from "../../../model/Total";
import { SectionPartComponent } from "../../sections/sectionPart/sectionPartComponent";
import { Stack } from "@mui/material";

export type TotalScorePropsType = {
    score: number;
    totalSection: Total;
    document: Document;
};

export const TotalScoreComponent: React.FC<TotalScorePropsType> = (
    props: TotalScorePropsType
) => {
    return (
        <Stack>
            <Stack direction="row"
                style={{
                    background: "lightgray",
                    padding: "10px 5px",
                    display: "flex",
                    justifyContent: "space-between",
                    border: "solid 1px #ececec",
                    borderTop: "none",
                }}
            >
                <Label styleName={classes["label"]}>{props.totalSection.question ?? 'Score:'}</Label>
                <Label styleName={classes["scoreLabel"]}>{props.score}</Label>
            </Stack>

            {props.totalSection?.parts?.map((part) => (
                <SectionPartComponent key={part.id} part={part} document={props.document} />
            ))}
        </Stack>
    );
};
