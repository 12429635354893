import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import {
    calculateSectionTotal,
    getSectionState,
} from "../slices/documentSlice";

export const sourceDocumentSelector = (state: RootState) => state.updateSourceDocument;

// export const sectionSelector = (sectionId: number) =>
//     createSelector(documentSelector, (document) =>
//         getSectionState(document, sectionId)
//     );

// export const sectionTotalSelector = (sectionId: number) =>
//     createSelector(sectionSelector(sectionId), (section) => {
//         if (!section) {
//             return 0;
//         }
//         return calculateSectionTotal(section);
//     });
