import React, { useState } from "react";
import { updateAnswer } from "../../../store/slices/documentSlice";
import { useAppDispatch } from "../../../store/hooks";
import * as helperMethods from "../../helpers/helperMethods";
import { LargeTextQuestion } from "../../../model/LargeTextQuestion";
import classes from "./largeTextQuestionComponent.module.scss";
import { Label } from "../../common/Label/Label"
import { TextInput } from "../../common/TextInput/TextInput";

import AppContext from "../../global";

type Props = {
    question: LargeTextQuestion;
    characterLimit?: number
};

const DEFAULT_CHARACTER_LIMIT = 300;

const LargeTextQuestionComponent: React.FC<Props> = (props: Props) => {
    const { question, characterLimit = DEFAULT_CHARACTER_LIMIT } = props;
    const dispatch = useAppDispatch();
    const [textFieldValue, setTextFieldValue] = useState(question.answer ?? "");
    const isRequired = helperMethods.isRequired(props);
    const isAllowed = helperMethods.isAllowed(props);

    function handleOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        dispatch(
            updateAnswer({
                id: question.id,
                answer: textFieldValue,
            })
        );
    }

    return (
        <div className={classes.container}>
            <Label styleName={classes["label"]}>
                {question.required
                    ? question.question + " *"
                    : question.question}
            </Label>

            {AppContext.isInPrintPreview ? (
                <Label styleName={classes.print_label}>{textFieldValue}</Label>
            ) : (
                <TextInput
                    className={classes.box}
                    aria-required
                    multiline
                    minRows={2}
                    error={isRequired}
                    inputProps={{ maxLength: characterLimit }}
                    label={isRequired ? "Required" : "" || isAllowed ? "Comment" : ""}
                    required={isRequired}
                    onChange={(e) => setTextFieldValue(e.target.value)}
                    onBlur={handleOnBlur}
                    defaultValue={question.answer}
                    helperText={`${textFieldValue.length}/${characterLimit}`}
                />)}
        </div>
    );
};

export default LargeTextQuestionComponent;
