import React from "react";

import { documentSelector } from "../../../../store/selectors/documentSelectors"
import { updateDocumentDiscipline } from "../../../../store/slices/documentSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { FormControlLabel, FormGroup } from "@mui/material";

import { Label } from "../../../common/Label/Label"
import { CheckBox } from "../../../common/CheckBox/CheckBox"

import classes from "./documentHeader.module.scss"

export type DocumentHeaderPropsType = {
    patientName?: string;
    onSelectDiscipline: (discipline: string) => void;
    discipline: string;
};

export const DocumentHeader: React.FC<DocumentHeaderPropsType> = (
    props: DocumentHeaderPropsType
) => {

    let document = useAppSelector(documentSelector)
    const dispatch = useAppDispatch();

    function handleDisciplineChecked(e: React.ChangeEvent<HTMLInputElement>, discipline: string) {
        dispatch(
            updateDocumentDiscipline({
                discipline: discipline,
                checked: e.target.checked,
            })
        );
    }
    return (
        <div className={classes.checkboxOuterDiv}>
            {props.patientName && (
                <Label styleName={classes.headerLabel}>
                    Screening for {props.patientName}
                </Label>
            )}

            <div className={classes.checkboxFormGroupDiv}>
                <FormGroup className={classes.checkboxFormGroup} >
                    {document?.allowedDisciplines?.map((discipline) => (
                        <FormControlLabel
                            className={classes.checkboxFormControlLabel}
                            control={
                                <CheckBox
                                    checked={!!document?.disciplines?.[discipline]}
                                    onChange={(e) => handleDisciplineChecked(e, discipline)}

                                />
                            }
                            key={discipline}
                            label={discipline}
                        />
                    ))}
                </FormGroup>
            </div>
        </div>
    );
};
