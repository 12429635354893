import * as api from "./sourceDocumentRepository";
import store from "../../store/configureStore"
import { Document } from "../../model/Document";
import { SourceDocument } from "../../model/SourceDocument";

export async function DeleteDocument(documentId: number) {
    await api.DeleteSourceDocument(documentId);
}

export async function CopyDocument(document: SourceDocument) {
    await api.SaveSourceDocument(document);
}

export async function GetSourceDocumentsByOrgId(orgId: string): Promise<SourceDocument[]> {
    return await api.GetSourceDocumentsByOrgId(orgId);
}

export async function SaveDocument(document: SourceDocument) {
    await api.SaveSourceDocument(document);
}

export async function UpdateDocument(document: SourceDocument) {
    await api.UpdateSourceDocument(document);
}