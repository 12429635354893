import axios from "axios";
import {
    checkTokenValidity,
    getAuthToken,
    persistToken,
} from "../repository/authRepository";

export interface ExtendedWindow extends Window {
    _env_?: any;
}

const { _env_ }: ExtendedWindow = window;

const apiUrlBase = _env_.REACT_APP_THERAPY_SCREENS_API_URL_BASE;

export const coreAxios = axios.create({
    baseURL: apiUrlBase,
});

coreAxios.interceptors.request.use(
    async (config) => {
        let authToken = getAuthToken();
        if (!authToken) {
            return config;
        }

        const isTokenValid = checkTokenValidity(authToken);
        if (!isTokenValid) {
            authToken = await fetchNewTokenApi(authToken);
        }

        config.headers["Authorization"] = `Bearer ${authToken}`;
        return config;
    },
    (error) => Promise.reject(error)
);

async function fetchNewTokenApi(authToken: string): Promise<string> {
    const resp = await axios.post(
        "${apiBaseUrl}/token/AlreadyAuthenticated",
        {
            token: authToken,
        }
    );
    const { token } = resp.data;
    persistToken(token);
    return token;
}
