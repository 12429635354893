import { AnswerCategory } from "../../model/AnswerCategory";
import { SelectQuestionOption } from "../../model/SelectQuestionOption";
import { SingleselectQuestion } from "../../model/SingleselectQuestion";
import AppContext from "../global";

export function isAllowed(props: any) {
    return props.question.comment === "allowed";
}
export function isRequired(props: any) {
    return props.question.comment === "required";
}

export function getScore(
    question: SingleselectQuestion
) {
    let score = 0;
    if(!AppContext) return score;
    let ac:AnswerCategory = AppContext.currentAnswerCategories?.find(ac => ac.answerCategoryName === (question as SingleselectQuestion ).answerCategory);
    let answer = (ac?.options as SelectQuestionOption[])?.find(o => o.value === (question as SingleselectQuestion ).answer)
    if( !!ac && !!answer)
        score =(ac.options as SelectQuestionOption[])?.find(o => o.value === (question as SingleselectQuestion ).answer).valueScore
    return score ;
}
