import { TextField, TextFieldProps } from "@mui/material";
import classes from "./TextInput.module.scss";

export const TextInput: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    return (
        // @ts-ignore
        <TextField
            {...props}
            InputProps={{
                classes: {
                    notchedOutline: classes.focusedInput,
                    input: classes.inputBox,
                    root: classes.root,
                },
            }}
        />
    );
};
