export async function GetDisplayVersion(): Promise<string> {
    const result = await fetch(`/dockerversiontag`, {
        method: "GET",
        headers: {
            "Content-Type": "text/plain",
        },
    });

    const resultingModel = await result.text();

    return resultingModel;
}