import { useState } from "react";
import classes from "./MaintenanceComponent.module.scss"
import { MaintenanceTable } from "../table/MaintenanceTable";
import { MaintenanceAppBar } from "./appbar/MaintenanceAppBar";
import { Box } from "@mui/material";
import useInitializeWindow from "../../../hooks/useInitializeWindow";
import usePersistToken from "../../../hooks/usePersistToken";
import useGetSourceDocuments from "../../../hooks/useGetSourceDocuments";
import { DocumentDialog } from "../../mainPage/document/DocumentDialog";
import { SourceDocument } from "../../../model/SourceDocument";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { NewSourceDocumentDialog } from "../dialog/NewSourceDocumentDialog";
import * as sourceDocumentApiHelper from "../../../repository/sourceDocument/sourceDocumentApiHelper"
import { EditScreenDialog } from "./edit/dialogs/EditScreenDialog";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateSourceDocument } from "../../../store/slices/sourceDocumentSlice";


export const MaintenanceComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    useInitializeWindow();
    usePersistToken();

    const confirmationDialog = useConfirmationDialog();

    const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [isMaintenanceEditOpen, setIsMaintenanceEditOpen] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState<SourceDocument>();
    const [filterText, setFilterText] = useState('');

    const [sourceDocuments, reloadData, initMessage] = useGetSourceDocuments(filterText);

    function showDocument(id: number) {
        let document = sourceDocuments.find(c => c.id == id);
        setSelectedDocument(document);
        setIsDocumentDialogOpen(true);
    }

    function deleteDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);

        confirmationDialog.show({
            message: `Are you sure you want to delete ${document.documentTemplate.screenReason.trim()}? This action cannot be undone!`,
            title: 'Please Confirm',
            onCancel: () => {
                confirmationDialog.hide();
                reloadData({});
            },
            onConfirm: async () => {
                await sourceDocumentApiHelper.DeleteDocument(document.id);
                confirmationDialog.hide();
                reloadData({})
            },
            cancelText: "No",
            confirmText: "Yes",
        });
    }

    function copyDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);
        setSelectedDocument(document);

        setIsCopy(true);
        setIsNewDialogOpen(true);
    }

    async function handleCopyDocument(copiedDocument: SourceDocument) {

        if (!copiedDocument.documentTemplate.screenReason) {
            confirmationDialog.show({
                message: `New Screen Name cannot be empty!`,
                title: 'Screen Name Error',
                onConfirm: () => {
                    confirmationDialog.hide();
                }
            });

            return;
        }

        reloadData({}); 
        const newDoc = copiedDocument.documentTemplate.screenReason.toLowerCase()
        
        if (!!sourceDocuments &&
            sourceDocuments
                .map(item => item.documentTemplate.screenReason.toLowerCase())
                .filter(item => item === newDoc)
                .length > 1

        ) {
            confirmationDialog.show({
                message: `New Screen Name must be unique!`,
                title: 'Screen Name Error',
                onConfirm: () => {
                    confirmationDialog.hide();
                    reloadData({}); 
                }
            });

            return;
        }

        copiedDocument.orgId = initMessage.orgId;
        await sourceDocumentApiHelper.CopyDocument(copiedDocument);
        reloadData({});
        setIsNewDialogOpen(false);
    }

    function editDocument(id: number) {
        let document = sourceDocuments.find(c => c.id === id);
        setSelectedDocument(document);
        dispatch(updateSourceDocument(document));
        setIsMaintenanceEditOpen(true);
    }

    function handleSaveFailed(message: string) {
        confirmationDialog.show({
            message: `${message}. Source document failed to save. Documents will refresh.`,
            title: 'Source Document Save Failed',
            onConfirm: () => {
                confirmationDialog.hide();
                setIsMaintenanceEditOpen(false);
                reloadData({});
            }
        });
    }

    function addNewScreen() {
        setIsCopy(false);
        setIsNewDialogOpen(true);
    }

    return (
        <>
            <MaintenanceAppBar
                filterSourceDocuments={setFilterText}
                addNewScreen={addNewScreen} />

            <Box className={classes.box}>
                <MaintenanceTable
                    screens={sourceDocuments}
                    showDocument={showDocument}
                    deleteDocument={deleteDocument}
                    copyDocument={copyDocument}
                    editDocument={editDocument} />
            </Box>

            <EditScreenDialog
                isOpen={isMaintenanceEditOpen}
                sourceDocument={selectedDocument}
                saveFailed={handleSaveFailed}
                close={() => {
                    setIsMaintenanceEditOpen(false);
                    reloadData({});
                }} />

            <DocumentDialog
                document={selectedDocument?.documentTemplate}
                isOpen={isDocumentDialogOpen}
                handleSaveEditDocument={async () => { }}
                handleCancelEditDocument={() => {
                    setIsDocumentDialogOpen(false);
                    reloadData({});
                }}
                handlePrintDocument={() => { }}
                patientName={"Preview"}
                previewMode={true} />

            <NewSourceDocumentDialog
                isOpen={isNewDialogOpen}
                isCopy={isCopy}
                sourceDocument={selectedDocument}
                handleSaveDocument={handleCopyDocument}
                handleCloseDialog={() => {
                    setIsNewDialogOpen(false);
                    reloadData({});
                }}
            />

            {confirmationDialog.component}
        </>
    );
};