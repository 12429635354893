
import { IsQuestion, IsSection, Document } from "../model/Document";
import { Total } from "../model/Total";
import { Section } from "../model/Section";
import { Question } from "../model/Question";
import { getAuthToken } from "./authRepository";
import dayjs from "dayjs";
import { getScore } from "../components/helpers/helperMethods";

export interface ExtendedWindow extends Window {
    _env_?: any;
}

const { _env_ }: ExtendedWindow = window;

const pdpmApiUrlBase = _env_.REACT_APP_PDPM_CALCULATOR_API_URL_BASE;

console.log("PDPM URL BASE: " + pdpmApiUrlBase, _env_);

type PDPMItem = {
    Mapping: string;
    Value: string;
    ValueScore?: number;
}
type PDPMRequestData = {
    PDPM_Items: PDPMItem[];
    ActivityDate: string;
    StateCode: string;
    County: string;
    Calculate_NTA_Nursing: boolean;
}

export type PDPMResultData = {
    Mapping: string;
    Therapy_FunctionScore: number;
    Nursing_FunctionScore: number;
    CMG_PT: string;
    CMI_PT: number;
    DOLLAR_PT: number;
    CMG_OT: string;
    CMI_OT: number;
    DOLLAR_OT: number;
    CMG_ST: string;
    CMI_ST: number;
    DOLLAR_ST: number;
    CMG_NTA: string;
    CMI_NTA: number;
    DOLLAR_NTA: number;
    CMG_Nursing: string;
    CMI_Nursing: number;
    DOLLAR_Nursing: number;
    NonCaseMix: number;
    DOLLAR_Total: number;
};

export async function GetPDPMScores(document: Document): Promise<PDPMResultData> {

    let strDoc:PDPMRequestData = GetMappedFields(document);
    if (!strDoc) return null;

    try {
        const result = await fetch(`${pdpmApiUrlBase}/v1/PDPMCalculator/ComputeScores/`, {
            method: "POST",
            body: JSON.stringify(strDoc),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: `Bearer ${getAuthToken()}`,
            },
        });
    
        if (!result.ok || result.status === 204) { // added 204 because not enough information was sent to create a score
            return null;
        }
    
        const resultingModel = await result.json(); // This can fail if the documents db isn't up to date
        if (!!resultingModel) {
            return resultingModel as PDPMResultData;
        }    else {
            return null;
        }
      } catch (error) {
        console.error('Error occurred:', (error as Error).message);
        return null;
    }

    

}

function GetMappedFields(document: Document): PDPMRequestData {
    let mappedQuestions: Question[] = [];

    document.sections.forEach((item) => {
        GetMappedRecursive(mappedQuestions, item);
    })
    let date = new Date(document.screenDate);
    let reqData: PDPMRequestData = {
        PDPM_Items: mappedQuestions.map(item => ({
            Mapping: item.mapping,
            Value: String(item.answer),
            ValueScore: item.kind === "select" ? getScore(item) : null,
        })),
        ActivityDate: dayjs(date).format("YYYY-MM-DD"),
        StateCode: document?.facility?.state,
        County: document?.facility?.county,
        Calculate_NTA_Nursing: true,
    };

    return reqData;
}

function GetMappedRecursive(mappedQuestions: Question[], item: Section | Question | Total) {
    if (IsQuestion(item) && item.mapping && !!item.answer) {
        mappedQuestions.push(item);
    } else if (IsSection(item)) {
        item.parts?.forEach((child) => {
            GetMappedRecursive(mappedQuestions, child);
        });
    }
}
