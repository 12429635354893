import React, { ReactElement, useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import classes from "./styles/rowstyles.module.scss";
import { ReactComponent as ChevronRight } from "bootstrap-icons/icons/chevron-right.svg"
import { ReactComponent as ChevronDown } from "bootstrap-icons/icons/chevron-down.svg"
import { ReactComponent as ChevronDblUp } from "bootstrap-icons/icons/chevron-double-up.svg"
import { ReactComponent as ChevronDblDown } from "bootstrap-icons/icons/chevron-double-down.svg"
import { ReactComponent as PlusLg } from "bootstrap-icons/icons/plus-lg.svg"
import { useAppDispatch } from "../../../../../store/hooks";
import { IsSection, IsTotal } from "../../../../../model/Document";
import { AnswerCategory } from "../../../../../model/AnswerCategory";
import { Total } from "../../../../../model/Total";
import { Section } from "../../../../../model/Section";
import { Question } from "../../../../../model/Question";
import { updatePosition, deleteItem } from "../../../../../store/slices/sourceDocumentSlice";
import { CheckBox } from "../../../../common/CheckBox/CheckBox";
import { useConfirmationDialog } from "../../../../../hooks/useConfirmationDialog";
import { Kinds } from "../../../../../model/Kind";


type Props = {
    row: Question | Section | Total,
    level: number,
    QuestionComponent: ReactElement,
    EditComponent: ReactElement,
    canMoveUp: boolean,
    canMoveDown: boolean,
    docInUse: boolean,
    docIsGlobal: boolean,
    addItem: (addAnchor: Question | Section | Total) => void
};

export const EditorRowComponent: React.FC<Props> = (props: Props) => {
    const [toggleExpanded, setToggleExpanded] = useState<Boolean>(false);
    const dispatch = useAppDispatch();
    const confirmationDialog = useConfirmationDialog();

    function moveRow(moveDirection: "Up" | "Down") {
        dispatch(
            updatePosition({
                id: props.row.id,
                MoveDirection: moveDirection
            })
        );
    }

    function DeleteSection(){
        dispatch(
            deleteItem({
                id: props.row.id
            })
            );
        confirmationDialog.hide();

    }

    function onDeleteSection() {

        const kindDescription = Kinds.find((kind) => kind.kindKey === props.row.kind) !== undefined
                            ? Kinds.find((kind) => kind.kindKey === props.row.kind).Desc
                            :''; 

        confirmationDialog.show({
            message: `Are you sure you want to delete this ${kindDescription} section?`,
            title: "Please Confirm",
            onCancel: () => {
                confirmationDialog.hide();
            },
            onConfirm: DeleteSection,
            cancelText: "No",
            confirmText: "Yes",
        });
    }

    return (
        <React.Fragment key={props.row.id}>
            <TableRow >
                <TableCell colSpan={1} className={classes.table_cell} style={{ height: '0', padding: '0 0 0 0' }}>
                    <div style={{ borderLeft: ((props.level - 1) * 4) + "px solid" }}
                        className={classes.moveAreaDiv}>
                        <IconButton
                            className={classes.icon}
                            aria-label="Move Up"
                            title={props.canMoveUp ? "Move Up" : ""}
                            disabled={!props.canMoveUp || props.docInUse}
                            onClick={() => moveRow("Up")}>
                            <ChevronDblUp />
                        </IconButton>
                    </div>
                    <div style={{ borderLeft: ((props.level - 1) * 4) + "px solid" }}
                        className={classes.moveAreaDiv}>
                        <IconButton
                            className={classes.icon}
                            aria-label="Move Down"
                            title={props.canMoveDown ? "Move Down" : ""}
                            disabled={!props.canMoveDown || props.docInUse}
                            onClick={() => moveRow("Down")}>
                            <ChevronDblDown />
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell colSpan={1} className={classes.table_cell}>
                    {toggleExpanded && (
                        <IconButton
                            className={classes.icon}
                            aria-label="Collapse"
                            title="Collapse"
                            onClick={() => setToggleExpanded(!toggleExpanded)}>
                            <ChevronDown />
                        </IconButton>)}
                    {!toggleExpanded && (
                        <IconButton
                            className={classes.icon}
                            aria-label="Expand"
                            title="Expand"
                            onClick={() => setToggleExpanded(!toggleExpanded)}>
                            <ChevronRight />
                        </IconButton>)}
                </TableCell>
                <TableCell colSpan={1} className={classes.table_cell}
                    title={IsSection(props.row) ? "Add" : ""}>
                    {IsSection(props.row) &&
                        <IconButton
                            className={classes.icon}
                            aria-label="Add"
                            onClick={() => props.addItem(props.row)}>
                            <PlusLg />
                        </IconButton>}
                </TableCell>
                <TableCell colSpan={1} align={"center"} className={classes.table_cell}>
                    {props.QuestionComponent}
                </TableCell>
                <TableCell colSpan={1} className={classes.table_cell} >
                    <CheckBox checked={props.row?.active || false} />
                </TableCell>
                <TableCell colSpan={1} className={classes.table_cell} >
                    <IconButton
                        className={classes.icon}
                        aria-label="delete"
                        disabled={props.docInUse || props.docIsGlobal}
                        onClick={() => { onDeleteSection() }}>
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
            {toggleExpanded && (<TableRow >
                <TableCell colSpan={6} className={classes.table_cell}>
                    {props.EditComponent}
                </TableCell>
            </TableRow>)}
            {confirmationDialog.component}
        </React.Fragment>
        
    )
}
