import documentReducer from "./slices/documentSlice";
import sourceDocumentReducer from "./slices/sourceDocumentSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
    reducer: {
        updateDocument: documentReducer,
        updateSourceDocument: sourceDocumentReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const selectDocumentState = (state: RootState) => state.updateDocument.sections;
export default store;


