import React from "react";
import { Document } from "../../model/Document";
import { Section } from "../../model/Section";
import classes from "./sectionComponent.module.scss";
import { SectionPartComponent } from "./sectionPart/sectionPartComponent";
import { Label } from "../common/Label/Label"

export const SectionComponent: React.FC<{
    section: Section;
    document: Document;
}> = (props) => {
    return (
        <>
            <div
                className={[
                    classes.section,
                    classes[props.section.formatType.toLowerCase()],
                ].join(" ")}
            >
                <div className={classes.scoreGroup}>
                    <Label styleName={classes[props.section.formatType.toLowerCase()]}>
                        {props.section.title}
                    </Label>
                </div>
                <Label styleName={props.section.formatType.toLowerCase()}>{props.section.caption}</Label>
            </div>
            <div>
                {props.section?.parts?.map((part) => (
                    <SectionPartComponent key={part.id} part={part} document={props.document} />
                ))}
            </div>
        </>
    );
};
