import { useCallback, useState } from "react"

export type UseDialogReturnType = {
    isOpen: boolean;
    toggle: (show?: boolean) => void;
    close: () => void;
    open: () => void;
}

export const useDialog = (defaultOpen: boolean): UseDialogReturnType => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const toggle = useCallback((show?: boolean) => {
        const shouldShow = show ?? !isOpen;
        setIsOpen(shouldShow)
    }, [isOpen])

    const close = useCallback(() => {
        toggle(false)
    }, [toggle])

    const open = useCallback(() => {
        toggle(true)
    }, [toggle])

    return {isOpen, toggle, close, open}
}