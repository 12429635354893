import React from "react";
import classes from './PatientInfoHeader.module.scss'
import { InitMessage } from "../../com_integration/models/InitMessage";

export const PatientInfoHeader: React.FC<{ initMessage?: InitMessage }> = (
    props
) => {
    return (
        <div
            className={classes.root}

        >
            <div className={classes.item}>
                <p>
                    <strong>MRN: </strong>{" "}
                    {props.initMessage?.patient?.medicalRecordNumber ?? "N/A"}
                </p>
            </div>
            <div className={classes.item}>
                <p>
                    <strong>Payer: </strong> {props.initMessage?.patient?.payer}
                </p>
            </div>
            {/* <Box className={classes.item}>
                <p><strong>Disc:</strong> {props.initMessage?.Discipline}</p>
            </Box> */}
            <div className={classes.item}>
                <p>
                    <strong>Start Of Care: </strong> {props.initMessage?.patient?.startOfCareDate}
                </p>
            </div>
            <div className={classes.item}>
                <p>
                    <strong>Admitted: </strong>
                    {props.initMessage?.patient?.admissionDate}
                </p>
            </div>
        </div>
    );
};
