import React, { useCallback, useState } from "react";
import * as cssStyles from "../../../styles/componentStyles";
import { useDispatch } from "react-redux";
import { updateNote } from "../../../store/slices/documentSlice";
import { TextInput } from "../../common/TextInput/TextInput";
import { Label } from "../../common/Label/Label";
import classes from "./questionCommentComponent.module.scss";

import AppContext from "../../global";

export type QuestionCommentProps = {
    questionId: number;
    noteText: string;
    showNotes: boolean;
    noteRequired: boolean;
};

export const QuestionComment: React.FC<QuestionCommentProps> = (
    props: QuestionCommentProps
) => {
    const dispatch = useDispatch();
    const [noteValue, setNoteValue] = useState(props.noteText);

    const isRequired = props.noteRequired
    const isAllowed = props.showNotes

    const handleTextFieldOnBlur = useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            dispatch(
                updateNote({
                    id: props.questionId,
                    noteText: noteValue,
                })
            );
        },
        [noteValue]
    );

    return (
        <div>
            {AppContext.isInPrintPreview ? (
                <Label styleName={classes.print_label}>{noteValue}</Label>
            ) : (
                <TextInput
                    className={classes.text_input}
                    aria-required={isRequired}
                    onBlur={handleTextFieldOnBlur}
                    onChange={(e) => setNoteValue(e.target.value)}
                    error={isRequired}
                    required={isRequired}
                    defaultValue={noteValue}
                    InputLabelProps={{ shrink: true }}
                    label={isRequired ? "Required" : "" || isAllowed ? "Comment" : ""}
                />
            )}
        </div>
    );
};
