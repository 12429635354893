import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import {
    calculateSectionTotal,
    getSectionState,
} from "../slices/documentSlice";

export const documentSelector = (state: RootState) => state.updateDocument;

export const sectionSelector = (sectionId: number) =>
    createSelector(documentSelector, (document) =>
        getSectionState(document, sectionId)
    );

export const sectionTotalSelector = (sectionId: number,totalType: string = "All") =>
    createSelector(sectionSelector(sectionId), (section) => {
        if (!section) {
            return 0;
        }
        return calculateSectionTotal(section,totalType);
    });
