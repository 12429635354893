import React from "react";
import { PDPMResultData } from "../../../repository/pdpmCalculatorRepository";
import { Label } from "../../common/Label/Label";
import classes from "./PDPMTotalScoreComponent.module.scss"
import { Stack } from "@mui/material";
import { Total } from "../../../model/Total";

export type PDPMTotalScorePropsType = {
    totalSection: Total;
    pdpmscores: PDPMResultData;
    gridMode?: boolean;
};

export const PDPMTotalScoreComponent: React.FC<PDPMTotalScorePropsType> = (
    props: PDPMTotalScorePropsType
) => {
    if (!!props.pdpmscores) {
        return (
            <div className={props.gridMode ? classes["gridmode-container"] : classes["container"]}>
                {(!props.gridMode) && (<div className={classes["title"]}>{props.totalSection?.question}</div>)}
                <div>
                    <Label styleName={props.gridMode ? classes["gridmode-header"] : classes["header"]}>{(props.pdpmscores?.Mapping ?? "")}</Label>
                </div>
                <div
                    style={{
                        padding: props.gridMode ? "0px" : "0px 10px",
                    }}>
                    <div>
                        <Label styleName={classes["label"]}>Tx Fx:</Label> <Label styleName={classes["scoreLabel"]}>{(props.pdpmscores?.Therapy_FunctionScore ?? "")}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>NSG Fx:</Label> <Label styleName={classes["scoreLabel"]}>{(props.pdpmscores?.Nursing_FunctionScore ?? "")}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>PT:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(props.pdpmscores?.CMG_PT, props.pdpmscores?.CMI_PT, props.pdpmscores?.DOLLAR_PT)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>OT:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(props.pdpmscores?.CMG_OT, props.pdpmscores?.CMI_OT, props.pdpmscores?.DOLLAR_OT)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>ST:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(props.pdpmscores?.CMG_ST, props.pdpmscores?.CMI_ST, props.pdpmscores?.DOLLAR_ST)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>NTA:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(props.pdpmscores?.CMG_NTA, props.pdpmscores?.CMI_NTA, props.pdpmscores?.DOLLAR_NTA)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>NSG:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(props.pdpmscores?.CMG_Nursing, props.pdpmscores?.CMI_Nursing, props.pdpmscores?.DOLLAR_Nursing)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>Non Case:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(null, null, props.pdpmscores?.NonCaseMix)}</Label>
                    </div>
                    <div>
                        <Label styleName={classes["label"]}>Total 2020 Rate:</Label> <Label styleName={classes["scoreLabel"]}>{formatText(null, null, props.pdpmscores?.DOLLAR_Total)}</Label>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
};

function formatText(cmg: string, cmi: number, dollar: number) {
    let res = "";

    if (!!cmg) {
        res += cmg;
    }

    if (cmi != null) {
        if (res.length > 0)
            res += "/";
        res += cmi;
    }

    if (dollar != null) {
        if (res.length > 0)
            res += "/";
        res += "$" + dollar.toFixed(2);
    }

    return res;
}