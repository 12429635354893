import { Document } from "../../../model/Document";
import { ResultDefinition } from "../../../model/ResultDefinition";
import { GetPDPMScores } from "../../../repository/pdpmCalculatorRepository";
import { findTotalScoreSection } from "../../../store/slices/documentSlice";

export async function getFormResult(score: number, document: Document) {
    let res: any = null;
    if (findTotalScoreSection(document)?.kind == "PDPMScore") {
        res = await GetPDPMScores(document);
        if (res == null) {
            res = "Incomplete"
        }
    } else {
        res = document.resultDefinitions?.find((r) => score >= r.valueFrom && score <= r.valueTo)?.valueName;
    }

    return res;
}