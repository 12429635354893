import { useCallback, useMemo, useState } from "react";
import {
    ConfirmationDialog,
    ConfirmationDialogProps,
} from "../components/common/ConfirmationDialog/ConfirmationDialogComponent";
import { useDialog } from "./useDialog";

export const useConfirmationDialog = (): {
    show: (config: Omit<ConfirmationDialogProps, "isOpen">) => void;
    component: JSX.Element;
    hide: () => void;
} => {
    const dialogState = useDialog(false);
    const [currentProps, setCurrentProps] = useState<
        Omit<ConfirmationDialogProps, "isOpen">
    >({
        onConfirm: () => {},
        message: "",
        title: "",
    });

    const show = useCallback(
        (config: Omit<ConfirmationDialogProps, "isOpen">) => {
            setCurrentProps({ ...config });
            dialogState.open();
        },
        [dialogState]
    );

    const ConfirmationComponent = useMemo(() => {
        return (
            <ConfirmationDialog {...currentProps} isOpen={dialogState.isOpen} />
        );
    }, [dialogState, currentProps]);

    return { show, component: ConfirmationComponent, hide: dialogState.close };
};
