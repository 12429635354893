import { IsDocument, Document } from "../../model/Document";
import { SourceDocument } from "../../model/SourceDocument";
import { getAuthToken } from "../authRepository";

export interface ExtendedWindow extends Window {
    _env_?: any;
}

const { _env_ }: ExtendedWindow = window;

export async function GetSourceDocumentsByOrgId(orgId: string): Promise<SourceDocument[]> {
    try {
        const response = await GetDocumentRequest(`${GetBaseUri()}/allSourceDocuments/${orgId}`, 'GET');
        return await response.json();
    } catch {
        return [];
    }
}

export async function GetSourceDocument(id: string): Promise<SourceDocument> {
    try {
        const response = await GetDocumentRequest(`${GetBaseUri()}/sourceDocuments/${id}`, 'GET');
        return await response.json();
    } catch {
        return;
    }
}

export async function SaveSourceDocument(document: SourceDocument): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/sourceDocuments/`, 'POST', JSON.stringify(document));

    if (!result.ok) {
        throw new Error("Save Failed");
    }
}

export async function UpdateSourceDocument(document: SourceDocument): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/sourceDocuments/`, 'PUT', JSON.stringify(document));

    if (!result.ok) {
        let errorText = (await result.text())?.replace('["', '')?.replace('"]', '');
        throw new Error(errorText);
    }
}

export async function DeleteSourceDocument(documentId: number): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/sourceDocuments/${documentId}/`, 'DELETE');

    if (!result.ok) {
        throw new Error("Delete Failed");
    }
}

export function GetBaseUri(): string {
    return `${_env_.REACT_APP_THERAPY_SCREENS_API_URL_BASE}/v1`;
}

export function GetDocumentRequest(uri: string, method: string, body: string = null): Promise<Response> {
    return fetch(uri, {
        method: method,
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
    });
}