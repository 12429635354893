import { AccessRight } from "./AccessRight";
import { AnswerCategory } from "./AnswerCategory";
import { CommentQuestion } from "./CommentQuestion";
import { Facility } from "./Facility";
import { MultiselectQuestion } from "./MultiselectQuestion";
import { Patient } from "./Patient";
import { Question } from "./Question";
import { ResultDefinition } from "./ResultDefinition";
import { Section } from "./Section";
import { Total } from "./Total";

export type Document = {
    id: string;
    patientId: number;
    facilityId: number;
    facilityName: string;
    orgId: string;
    requestedDate: string;
    screenReason: string;
    screenResult: string;
    screenResultJSON?: any;
    screenDate: string;
    orderDate: string;
    soc: string;
    accessRights: AccessRight;
    startOfCareDates: { stDate: string, ptDate: string, otDate: string; };
    allowedDisciplines: string[];
    completingUser: string;
    disciplines: { PT: boolean, OT: boolean, ST: boolean };
    resultDefinitions: ResultDefinition[];
    sections: (Section | Question | Total)[];
    patient: Patient;
    facility: Facility;
    sourceDocumentId: number;
    answerCategories: AnswerCategory[];
};

export function IsSection(section: any): section is Section {
    return !!section && !!section.kind && section.kind === "section";
}

export function IsTotal(section: any): section is Total {
    return (
        !!section &&
        !!section.kind &&
        ["PDPMScore", "totalScore", "optionalScore", "requiredScore", "SCORE_SUBHEADER", "sectiontotal"].includes(section.kind)
    );
}

export function IsDocument(document: any): document is Document {
    return !!document.sections;
}

export function thisAnswer(answer: any): answer is MultiselectQuestion {
    return answer.kind; //Function to return the data type from the multi select
}

export function IsQuestion(question: any): question is Question {
    const questionKinds = [
        "LARGE_TEXT",
        "numeric",
        "date",
        "multiselect",
        "TEXT",
        "DATE",
        "select",
        "check",
        "PHONE_NUMBER"
    ];

    return questionKinds.includes(question.kind);
}

export function IsComment(question: any): question is CommentQuestion {
    return question.kind === "COMMENT";
}

export interface ISection { }
