import React from "react";
import { Label } from "../../common/Label/Label";
import classes from "./sectionTotalComponent.module.scss"

export type SectionTotalProps = {
    score: number;
};

export const SectionTotalComponent: React.FC<SectionTotalProps> = (
    props: SectionTotalProps
) => {
    return (
        <div
            style={{
                padding: "10px 5px",
                border: "solid 1px #ececec",
                borderTop: "none",
            }}
        >
            <Label styleName={classes["label"]}>Subtotal Score: {props.score || 0}</Label>
        </div>
    );
};
