
type Message = {
    /**
     * The message kind should be used as the discriminator column that indicates the general kind of the message.
     * - If considering through the lens of remote procedure calls then this is the method name.
     * - If considering through the lens of messaging then this is the channel
     **/
    kind: string;
    /**
     * This is the actual data that is sent back and forth. Observe that it is a string. It may contain any kind of data from
     * - Base 64 encoded binary
     * - A simple data type
     * - Or, most often, a JSON object which you can [en,de]code with JSON.stringify and JSON.parse.
     */
    payload: string;
    /**
     * The correlationId is only used in cases where there are send/receive semantics.
     * In those cases, if you do not set the correlation id then the API will set one for you.
     */
    correlationId?: string;
};
type MessageHandler = {
    /** The kind of message is kind of like a channel. */
    kind: string;
    handler: (msg: Message) => void;
};
type HandleError = (
    error: string,
) => {
    shouldContinue: boolean;
};
type ConfigureOptions = {
    /**
     * Provides a handler for any errors which occur inside the host messenger.
     */
    handleError: HandleError;
};
type HostMessenger = {
    /** Send a message from the web client to the host .NET browser control. */
    sendMessageToHost(msg: Message): void;
    /**
     * Send a message from the web client to the host and expect a respose message from the .NET host where the response is correlated through a correlationId.
     *
     * **NOTE** If you do not set a correlationId on the message you are sending to the host then the API will generate one for you.
     **/
    callToHost(msgToHost: Message): Promise<Message>;
    /** Listen to messages from the host. */
    subscribeToMessageFromHost(handler: MessageHandler): () => void;
    /**
     * Call a single time to configure the host messenger.
     **/
    configure(args: ConfigureOptions): void;
};
type HandleCloseRequest = () => Promise<{
    canClose: boolean;
}>;
type ConfigOptions = {
    handleError: HandleError;
    handleCloseRequest: HandleCloseRequest;
};
export type ContainerHostMessenger = HostMessenger & {
    /** Tell the container to update the name/caption of the embedded application (this can be used to update a form caption or a tab). */
    notifyFormNameUpdate(formName: string): void;
    /** Tell the container that the embedded application is ready to shut down. */
    closeForm(): void;
    /** Ask the container to reload the web application - this might be sent in response to a web worker notifying the client that new resources are available. */
    reloadForm(): void;
    /** Ask the host container to challenge the user for credentials. */
    credentialChallenge(): Promise<{ authenticated: boolean }>;
    /** **ONE TIME** call of configure the host. */
    configureForHost(config: ConfigOptions): void;
};

type WindowInHost = Window &
    typeof globalThis & {
        hostMessenger?: ContainerHostMessenger;
    };

let _initialized = false;
/**
 * Initialize interaction between this embedded application and the host container.
 */
export function clientInitialize(): ContainerHostMessenger | null {
    if (!!_initialized)
        throw new Error("Please do not initialize Host integration multiple times.");
    _initialized = true;
    const { hostMessenger } = window as WindowInHost;
    if (!hostMessenger) {
        console.log("com integration - no host!", window);
        return null;
    }
    else {
        /** Configure basic queries from the host to this application. */
        hostMessenger.configureForHost({
            handleError: (error) => {
                console.log(`there was a problem: ${error}`);
                return { shouldContinue: true };
            },
            handleCloseRequest: () => {
                return Promise.resolve({ canClose: true });
            },
        });
        hostMessenger.subscribeToMessageFromHost({
            kind: "InitVariables",
            handler: (msg: Message) => {
                console.log('InitVariables', msg)
                const json = JSON.parse(msg.payload);
                if (!!(window as any).initializeAppComponent) {
                    (window as any).initializeAppComponent(json);
                }
                else {
                    console.log(`there was a problem: missing initializeAppComponent method`);
                }
            }
        });
        hostMessenger.subscribeToMessageFromHost({
            kind: "RxToken",
            handler: (msg: Message) => {
                console.log('RxToken', msg)
                if (!!(window as any).initializeRxToken) {
                    (window as any).initializeRxToken(msg.payload);
                }
                else {
                    console.log(`there was a problem: missing initializeRxToken method`);
                }
            }
        });
        
        // /** Setup the current page caption synchronization */
        // let subscription = null;
        // Page.registerAfterNavigatedToPage((page) => {
        //     if (!!subscription) {
        //         subscription.dispose();
        //         subscription = null;
        //     }

        //     if (!!page.getPageCaptionRx)
        //         subscription = page.getPageCaptionRx().subscribe((caption) => {
        //             hostMessenger.notifyFormNameUpdate(caption);
        //         });
        //     else hostMessenger.notifyFormNameUpdate(page.pageName);
        // });

        return hostMessenger;
    }
}
