import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { documentSelector } from "../../../store/selectors/documentSelectors"

import { DocumentComponent } from "../document/DocumentComponent";

import { Button } from "../../common/Button/Button";
import { Label } from "../../common/Label/Label";

import classes from "./PrintComponent.module.scss"
import AppContext from "../../global";
import { Container } from "@mui/material";

export const PrintComponent: React.FC = () => {

    let selectedDocument = useAppSelector(documentSelector)

    const navigate = useNavigate();
    const location = useLocation();

    let patientName = location?.state?.patientName ?? "";
    let initMessage = location?.state?.initMessage;

    AppContext.isInPrintPreview = true;

    function handleCancel() {
        navigate("/", {
            state: {
                initMessage: initMessage,
            }
        });
    }

    function handlePrint() {
        window.print();
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.header_button}>
                    <Button onClick={handleCancel} type="secondary">Cancel</Button>
                    <Label styleName={classes.print_preview_label} >Print Preview</Label>
                    <Button onClick={handlePrint}>Print</Button>
                </div>
            </div>
            <Container className={classes.documentDiv}>
                <DocumentComponent
                    patientName={patientName}
                    document={selectedDocument}
                    discipline=""
                    setDiscipline={null}
                />
                <Label styleName={classes.print_preview_watermark}>Print Preview</Label>
            </Container>
        </div>
    );
};