import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { SourceDocument } from "../../../model/SourceDocument";
import React, { } from "react";
import classes from "./MaintenanceTable.module.scss";
import { MaintenanceTableRow } from "./MaintenanceTableRow";

type Props = {
    screens: SourceDocument[],
    showDocument,
    deleteDocument,
    copyDocument,
    editDocument
};

export const MaintenanceTable: React.FC<Props> = (props: Props) => {
    return (
        <>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader className={classes.table}
                    aria-label="customized table">
                    <TableHead>
                        <TableRow className={classes.table_head}>
                            <TableCell className={classes.smallCell}>In Use</TableCell>
                            <TableCell className={classes.cell}>Screen Name</TableCell>
                            <TableCell className={classes.cell}>Header Text</TableCell>
                            <TableCell className={classes.largeCell}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.screens?.map((row) => (
                            <MaintenanceTableRow
                                key={row.documentTemplate.screenReason}
                                row={row}
                                showDocument={props.showDocument}
                                deleteDocument={props.deleteDocument}
                                copyDocument={props.copyDocument}
                                editDocument={props.editDocument}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    )
}
