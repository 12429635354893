import React from "react";
import { Document } from "../../../../../../model/Document";
import { SourceDocument } from "../../../../../../model/SourceDocument";
import { Total } from "../../../../../../model/Total";
import { Section } from "../../../../../../model/Section";
import { Question } from "../../../../../../model/Question";
import classes from "./sectionEditorComponent.module.scss";
import { SectionPartEditorComponent } from "./sectionPartEditor/sectionPartEditorComponent";
import { Label } from "../../../../../common/Label/Label"
import { TableBody, TableRow, TableCell } from "@mui/material";
import { EditorRowComponent } from "../EditorRowComponent";
import { SectionComponent } from "../../../../../sections/sectionComponent";
import { EditRowFieldsComponent } from "../EditRowFieldsComponent";

export const SectionEditorComponent: React.FC<{
    section: Section;
    parent: Section | Total;
    sourceDocument: SourceDocument;
    level: number,
    parentsList: (Section | Total)[];
    canMoveUp: boolean;
    canMoveDown: boolean;
    addItem: (addAnchor: Question | Section | Total) => void;
}> = (props) => {

    function EditorComponent() {
        return <SectionComponent section={
            {
                ...props.section,
                parts: null // suppress children because we are handling them here
            }

        } document={{ screenResultJSON: "" } as Document} />
    }

    return (
        <>
            <EditorRowComponent row={props.section} level={props.level}
                QuestionComponent={EditorComponent()}
                EditComponent={<EditRowFieldsComponent part={props.section} parent={props.parent} parentsList={props.parentsList} sourceDocument={props.sourceDocument} />}
                canMoveUp={props.canMoveUp}
                canMoveDown={props.canMoveDown}
                docInUse={props.sourceDocument.isInUse}
                docIsGlobal={props.sourceDocument.isGlobal}
                addItem={props.addItem} />
            {props.section?.parts?.map((part, ix) => (
                <SectionPartEditorComponent key={part.id} part={part} parent={props.section} level={props.level + 1} parentsList={props.parentsList} sourceDocument={props.sourceDocument}
                    canMoveUp={ix !== 0}
                    canMoveDown={ix !== props.section?.parts?.length - 1}
                    addItem={props.addItem} />
            ))}
        </>
    );
};
