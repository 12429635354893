import React from "react";
import { MenuItem, MenuItemProps } from "@mui/material";
import classes from "./DropdownOption.module.scss";

export const DropdownOption: React.FC<MenuItemProps> = (
    props: MenuItemProps
) => {
    return (
        <MenuItem
            {...props}
            dense={true}
            className={[props.className, classes.root].join(" ")}
            focusVisibleClassName={classes.focused}
        >
            {props.children}
        </MenuItem>
    );
};
