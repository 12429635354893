import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { Document } from "../../../model/Document";
import { Button } from "../../common/Button/Button";
import classes from "./DocumentDialog.module.scss";
import { DocumentComponent } from "./DocumentComponent";

export type DocumentDialogProps = {
    document: Document;
    isOpen: boolean;
    handleSaveEditDocument: () => Promise<void>;
    handleCancelEditDocument: () => void;
    handlePrintDocument: () => void;
    patientName?: string;
    previewMode: boolean;
    isSaving?: boolean;
};

export const DocumentDialog: React.FC<DocumentDialogProps> = (
    props: DocumentDialogProps
) => {
    const [discipline, setDiscipline] = useState("");
    function saveDocument() {
        props.handleSaveEditDocument();
    }

    function cancel() {
        props.handleCancelEditDocument();
    }

    function print() {
        props.handlePrintDocument();
    }

    return (
        <Dialog
            scroll="paper"
            fullWidth maxWidth="md"
            className={classes.container}
            open={props.isOpen}
            disableScrollLock>
            <DialogContent className={classes.container}>
                <DocumentComponent
                    patientName={props.patientName}
                    document={props.document}
                    discipline={discipline}
                    setDiscipline={setDiscipline}
                />
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Close
                </Button>
                {((!props.document?.screenResult || props.document?.screenResult == "Incomplete") && !props.previewMode) && (
                    <Button type="primary" onClick={saveDocument} disabled={!!props.isSaving}>
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
