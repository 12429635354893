import classes from "./LoadingComponent.module.scss"
import { CircularProgress, Stack } from "@mui/material"
import { Label } from "../Label/Label"


export const LoadingComponent: React.FC = () => {
    return (
        <div className = {classes.circularProgressBox}>
            <Stack className = {classes.childDiv}>
                <CircularProgress className = {classes.circularProgress}></CircularProgress>
                <Label styleName = {classes.label}>Fetching Data...</Label>
            </Stack>
        </div>
    )
}