import { useEffect, useState } from "react";
import { SourceDocument } from "../model/SourceDocument";
import { Section } from "../model/Section";
import * as documentApiHelper from "../repository/sourceDocument/sourceDocumentApiHelper";
import { InitMessage } from "../com_integration/models/InitMessage";

export default function useGetSourceDocuments(filterText: string) {
    const [sourceDocuments, setSourceDocuments] = useState<SourceDocument[]>();
    const [defaultSourceDocuments, setDefaultSourceDocuments] = useState<SourceDocument[]>();
    const [initMessage, setInitMessage] = useState<InitMessage>();
    const [refresh, reloadData] = useState({});

    useEffect(() => {
        (window as any).initializeAppComponent = (data: InitMessage) => {
            fetchSourceDocuments(data.orgId);
            setInitMessage(data);
        };
    }, []);

    useEffect(() => {
        if (initMessage) {
            fetchSourceDocuments(initMessage.orgId);
        }
    }, [refresh]);

    useEffect(() => {
        if (filterText) {
            setSourceDocuments(defaultSourceDocuments.filter(c => c.documentTemplate.screenReason.toLowerCase().includes(filterText.toLowerCase())
                || (c.documentTemplate?.sections[0] as Section)?.title.toLowerCase().includes(filterText.toLowerCase())));
        }
        else {
            setSourceDocuments(defaultSourceDocuments);
        }
    }, [filterText]);

    async function fetchSourceDocuments(orgId: string) {
        let sources = await documentApiHelper.GetSourceDocumentsByOrgId(orgId);
        let sortedSources = sources?.sort(function (a, b) {
            return a.documentTemplate.screenReason?.toLowerCase().localeCompare(b.documentTemplate.screenReason?.toLowerCase());
        });

        setSourceDocuments(sortedSources);
        setDefaultSourceDocuments(sortedSources);
    }

    return [sourceDocuments, reloadData, initMessage] as const;
}