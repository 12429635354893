export type Kind = {
    kindKey: string;
    kind: string;
    formatType: string;
    Desc: string;
};

export const Kinds: Kind[] =  
    [{ kindKey: "LARGE_TEXT", kind: "LARGE_TEXT", formatType: "", Desc: "Large Text" }
    , { kindKey: "TEXT", kind: "TEXT", formatType: "", Desc: "Text" }
    , { kindKey: "numeric", kind: "numeric", formatType: "", Desc: "Increment" }
    , { kindKey: "DATE", kind: "DATE", formatType: "", Desc: "Date" }
    , { kindKey: "checkCHECK_LEFT", kind: "check", formatType: "CHECK_LEFT", Desc: "Check Left" }
    , { kindKey: "checkCHECK_RIGHT", kind: "check", formatType: "CHECK_RIGHT", Desc: "Check Right" }
    , { kindKey: "COMMENT", kind: "COMMENT", formatType: "", Desc: "Comment" }
    , { kindKey: "multiselect", kind: "multiselect", formatType: "", Desc: "Multiselect" }
    , { kindKey: "select", kind: "select", formatType: "", Desc: "Dropdown" }
    , { kindKey: "sectionHEADER", kind: "section", formatType: "HEADER", Desc: "Header Section" }
    , { kindKey: "sectionSUBHEADER", kind: "section", formatType: "SUBHEADER", Desc: "Subheader Section" }
    , { kindKey: "sectionTITLE", kind: "section", formatType: "TITLE", Desc: "Title Section" }
    , { kindKey: "sectionSUBTITLE", kind: "section", formatType: "SUBTITLE", Desc: "Subtitle Section" }
    , { kindKey: "sectiontotal", kind: "sectiontotal", formatType: "", Desc: "Section Total" }
    , { kindKey: "SCORE_SUBHEADER", kind: "SCORE_SUBHEADER", formatType: "", Desc: "Subheader Score" }
    , { kindKey: "totalScore", kind: "totalScore", formatType: "", Desc: "Total Score" }
    , { kindKey: "optionalScore", kind: "optionalScore", formatType: "", Desc: "Optional Score" }
    , { kindKey: "requiredScore", kind: "requiredScore", formatType: "", Desc: "Required Score" }
    , { kindKey: "PHONE_NUMBER", kind: "PHONE_NUMBER", formatType: "", Desc: "Phone Number" }]

