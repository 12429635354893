import { Select, SelectProps } from "@mui/material";
import classes from "./Dropdown.module.scss";

export const Dropdown: <T>(props) => JSX.Element = (
    props
) => {
    return (
        <Select
            {...props}

            componentsProps={{
                input: {
                    className: classes.input,
                },
            }}
            className={[props.className, classes.root].join(" ")}
            MenuProps={{
                MenuListProps: {
                    disablePadding: true,
                },
            }}
        >
            {props.children}
        </Select>
    );
};
