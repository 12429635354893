import { IconButton, Box, Stack } from "@mui/material"
import { ResultDefinition } from "../../../../model/ResultDefinition";
import { Button } from "../../../common/Button/Button"
import { Label } from "../../../common/Label/Label"
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridActionsCellItem,
    GridEditInputCell,
    GridRowId,
    GridEventListener,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from "./EditScreenFormResultsComponent.module.scss"
import { CheckBox } from "../../../common/CheckBox/CheckBox";

import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { sourceDocumentSelector } from "../../../../store/selectors/sourceDocumentSelectors";
import { addResultDefinition, removeResultDefinition, updateResultDefinition } from "../../../../store/slices/sourceDocumentSlice";

export const EditScreenFormResultsComponent: React.FC = (props) => {

    const dispatch = useAppDispatch();

    let sourceDocument = useAppSelector(sourceDocumentSelector);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [rows, setRows] = useState(sourceDocument?.documentTemplate?.resultDefinitions?.map((a) => (
        {
            ...a,
            screenReason: sourceDocument.id,
            isNew: false,
            isEditableUntilSave: !sourceDocument.isInUse
        })) ?? []);

    const columns: GridColDef[] = [
        {
            field: 'true',
            headerName: 'In Use',
            type: 'actions',
            width: 100,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <CheckBox
                    checked={sourceDocument.isInUse && !params.row.isEditableUntilSave}
                    onChange={(e) => { }}
                    inputProps={{ "aria-label": "controlled" }}
                />
            )
        },
        {
            field: 'screenReason',
            headerName: 'Screen Reason',
            width: 130,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'valueFrom',
            headerName: 'Start Value',
            type: 'number',
            width: 100,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: 'valueTo',
            headerName: 'End Value',
            type: 'number',
            width: 100,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: 'valueName',
            headerName: 'Result',
            flex: 0.3,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: 'left',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            headerClassName: 'super-app-theme--header',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const isEditable = rows.find((a) => a.resultTypeId == id).isEditableUntilSave;

                if (!isEditable) {
                    return [];
                }

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon className={classes.icon} />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon className={classes.icon} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon className={classes.icon} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon className={classes.icon} />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    function getMaxId(): number {
        let sortedList = [...rows]?.sort(c => c.resultTypeId);
        return sortedList[sortedList?.length - 1]?.resultTypeId ?? 0
    }

    function isAnyRowCurrentlyInEditMode(): boolean {
        let isRowInEditMode = false;

        rows.some(c => {
            isRowInEditMode = rowModesModel[c.resultTypeId]?.mode === GridRowModes.Edit;
            return isRowInEditMode;
        })

        return isRowInEditMode;
    }

    const handleInsertClick = () => {
        if (!isAnyRowCurrentlyInEditMode()) {
            let newId = getMaxId() + 1;

            setRows((oldRows) => [...oldRows,
            {
                isNew: true,
                isEditableUntilSave: true,
                screenReason: sourceDocument.id,
                valueFrom: 0,
                valueTo: 0,
                valueName: '',
                kind: '',
                orderBy: 0,
                resultTypeId: newId
            }]);

            setRowModesModel((oldModel) => ({
                ...oldModel,
                [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'valueFrom' },
            }));
        }
        else {
            showSnackBar("Finish editing the current row first.");
        }
    }

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'valueFrom' } });
    }

    const handleDeleteClick = (id: GridRowId) => () => {
        let definition = {
            resultDefinition: rows.find(row => row.resultTypeId == id) as ResultDefinition
        };

        setRows(rows?.filter((row) => row.resultTypeId !== id));
        dispatch(removeResultDefinition(definition));
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows?.find((row) => row.resultTypeId === id);
        if (editedRow!.isNew) {
            setRows(rows?.filter((row) => row.resultTypeId !== id));
        }
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (
        params,
        event
    ) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }

    const processRowUpdate = (newRow) => {
        newRow.valueName = newRow.valueName.trim();

        const updatedRow = { ...newRow, isNew: false };
        let row = rows?.map((row) => (row.resultTypeId === newRow.resultTypeId ? updatedRow : row));
        let previousRow = rows?.find((row) => row.resultTypeId === (newRow.resultTypeId - 1));

        if (validate(newRow, previousRow)) {
            setRows(row);

            let definition = {
                resultDefinition: {
                    kind: updatedRow.kind,
                    orderBy: updatedRow.orderBy,
                    resultTypeId: updatedRow.resultTypeId,
                    screenReason: updatedRow.screenRow,
                    valueFrom: updatedRow.valueFrom,
                    valueTo: updatedRow.valueTo,
                    valueName: updatedRow.valueName
                } as ResultDefinition
            };

            if (newRow.isNew) {
                dispatch(addResultDefinition(definition));
            } else {
                dispatch(updateResultDefinition(definition));
            }

            closeSnackbar();

            return updatedRow;
        }
    };

    const showSnackBar = (message: string) => {
        const action = snackbarId => (
            <IconButton
                className={classes.icon}
                aria-label="preview"
                onClick={() => { closeSnackbar(snackbarId) }}>
                <CloseIcon style={{ fill: 'white' }} />
            </IconButton>
        );

        enqueueSnackbar(message,
            {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                preventDuplicate: true,
                autoHideDuration: 9000,
                action: action,
            });
    }

    function validate(row, previousRow): boolean {

        let isValidated = true;

        if (!row?.valueName || (row?.valueFrom ?? -1) < 0 || (row?.valueTo ?? -1) < 0) {
            showSnackBar(`Must enter a 'Start Value', 'End Value', and 'Result'`);
            isValidated = false;
        }
        else if ((row?.valueTo ?? 0) <= (row?.valueFrom ?? 0)) {
            showSnackBar(`'End Value' must be greater than 'Start Value'`);
            isValidated = false;
        }
        else if (previousRow && (row?.valueFrom ?? 0) <= previousRow?.valueTo) {
            showSnackBar(`'${row?.valueName}' 'Start Value' should be greater than '${previousRow?.valueName} 'End Value'`)
            isValidated = false;
        }

        return isValidated;
    }

    return (
        <Box sx={{
            '& .disabled': {
                backgroundColor: '#E3E4E3',
            }
        }}>
            <Stack>
                <Label styleName={classes.notes_header}>Notes:</Label>
                <Label styleName={classes.notes_label}>1. To add a <span className={classes.bold_notes}>New Result Value</span>, tap "Insert New Value". Tap "Save Results" button to apply the changes.</Label>
                <Label styleName={classes.notes_label}>2. You can <span className={classes.bold_notes}>modify existing result row </span> values by typing new values in the "Start Value", "End Value", "Value Name", and "Order" fields, as well as changing the "Active" flag. Tap "Save Results" button to apply the changes.</Label>
                <Label styleName={classes.notes_label}>3. To <span className={classes.bold_notes}>remove a result row</span> click the trash can icon. Tap "Save Results" button to apply the changes.</Label>
                <Label styleName={classes.notes_label}>4. If results for this form are <span className={classes.bold_notes}>In Use </span> by exist forms that is being used for patients, you will not be able to modify the values, and rows will be read-only marked with a gray background.</Label>
            </Stack>

            <div className={classes.insert_button}>
                <Button onClick={handleInsertClick}>Insert New Value</Button>
            </div>

            {rows?.length > 0 && (
                <DataGrid
                    scrollbarSize={15}
                    className={classes.datagrid}
                    getRowId={row => row?.resultTypeId}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    editMode="row"
                    rowModesModel={rowModesModel}
                    pageSizeOptions={[]}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    onRowEditStop={handleRowEditStop}
                    isRowSelectable={param => false}
                    isCellEditable={param => param.row.isEditableUntilSave}
                    getRowClassName={param => sourceDocument.isInUse && !param.row.isEditableUntilSave ? 'disabled' : ''} />
            )}
        </Box>
    )
}