import React from "react";
import { Document, IsSection } from "../../../model/Document";

import { SectionComponent } from "../../sections/sectionComponent";
import { DocSignerComponent } from "./DocumentSigner/DocSignerComponent";

export const DocumentBodyComponent: React.FC<{ document: Document }> = (props) => {
    return (
        <>
            <div>
                {props.document.sections?.flatMap((section) =>
                    IsSection(section) ? (
                        <SectionComponent key={section.id} section={section} document={props.document} />
                    ) : null
                )}
            </div>
            <div>
                <DocSignerComponent completingUser={props.document.completingUser} />
            </div>
        </>
    );
};
