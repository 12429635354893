import React, { useState } from "react";
import { Box } from "@mui/material";
import { updateAnswer } from "../../../store/slices/documentSlice";
import { useAppDispatch } from "../../../store/hooks";
import * as helperMethods from "../../helpers/helperMethods";
import { TextQuestion } from "../../../model/TextQuestion";
import classes from "./textQuestionComponent.module.scss";
import { QuestionComment } from "../questionComment/questionCommentComponent";
import { Label } from "../../common/Label/Label"
import { TextInput } from "../../common/TextInput/TextInput";

import AppContext from "../../global";

type Props = { question: TextQuestion };

const TextQuestionComponent: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const [textFieldValue, setTextFieldValue] = useState(props.question.answer ?? "");

    const isRequired = helperMethods.isRequired(props);
    const isAllowed = helperMethods.isAllowed(props);

    function handleOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        dispatch(
            updateAnswer({
                id: props.question.id,
                answer: textFieldValue,
            })
        );
    }

    return (
        <Box className={classes.container}>
            <div className={classes.editorDiv}>
                <Label styleName={classes.label}>
                    {props.question.required
                        ? props.question.question + " *"
                        : props.question.question}
                </Label>

                {AppContext.isInPrintPreview ? (
                    <Label styleName={classes.print_label}>{textFieldValue}</Label>
                ) : (
                    <TextInput
                        className={classes.inputField}
                        aria-required
                        error={isRequired}
                        label={isRequired ? "Required" : "" || isAllowed ? "Comment" : ""}
                        required={isRequired}
                        onChange={(e) => setTextFieldValue(e.target.value)}
                        onBlur={handleOnBlur}
                        defaultValue={props.question.answer}
                    />
                )}
            </div>

            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>
    );
};

export default TextQuestionComponent;
