import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material"
import React from "react"
import classes from "./MaintenanceTableRow.module.scss"
import { CheckBox } from "../../common/CheckBox/CheckBox";
import { CopyAll, Delete, Edit, FindInPageOutlined } from "@mui/icons-material";

type Props = {
    row,
    showDocument,
    deleteDocument,
    copyDocument,
    editDocument
};

export const MaintenanceTableRow: React.FC<Props> = (props) => {
    return (
        <TableRow key={props.row.documentTemplate.screenReason}>
            <TableCell className={classes.table_cell} align="center">
                <CheckBox checked={props.row?.isInUse} />
            </TableCell>
            <TableCell className={classes.left_table_cell}>
                {props.row.documentTemplate.screenReason}
            </TableCell>
            <TableCell className={classes.left_table_cell}>
                {props.row.documentTemplate?.sections[0]?.title}
            </TableCell>
            <TableCell className={classes.table_cell}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={6}>
                    <Tooltip title={<span className={classes.toolTip}>Preview</span>}>
                        <IconButton
                            className={classes.icon}
                            aria-label="preview"
                            onClick={() => { props.showDocument(props.row.id) }}>
                            <FindInPageOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<span className={classes.toolTip}>Copy</span>}>
                        <IconButton
                            className={classes.icon}
                            aria-label="copy"
                            onClick={() => { props.copyDocument(props.row.id) }}>
                            <CopyAll />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={
                        <span className={classes.toolTip}>
                            {props.row?.isGlobal ? 'Document cannot be edited.'
                                : 'Edit'}
                        </span>
                    }>
                        <span>
                            <IconButton
                                className={classes.icon}
                                aria-label="edit"
                                disabled={props.row?.isGlobal}
                                onClick={() => { props.editDocument(props.row.id) }}>
                                <Edit />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={
                        <span className={classes.toolTip}>
                            {props.row?.isGlobal ? 'Document cannot be deleted.'
                                : props.row.isInUse ? 'Document Is In Use: Cannot be deleted.'
                                    : 'Delete'}
                        </span>
                    }>
                        <span>
                            <IconButton
                                className={classes.icon}
                                aria-label="delete"
                                disabled={props.row.isInUse || props.row?.isGlobal}
                                onClick={() => { props.deleteDocument(props.row.id) }}>
                                <Delete />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    )
}