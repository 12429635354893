
import { IsDocument, Document } from "../../model/Document";
import { getAuthToken } from "../authRepository";

export interface ExtendedWindow extends Window {
    _env_?: any;
}

const { _env_ }: ExtendedWindow = window;

export async function GetDocument(id: string): Promise<Document> {
    const response = await GetDocumentRequest(`${GetBaseUri()}/${id}`, 'GET');
    const model = await response.json();

    if (IsDocument(model)) {
        return model;
    }

    throw new Error("Got invalid data from the server.")
}

export async function GetAllDocumentsByPatientId(
    patientId: string,
    facilityId: string,
    orgId: string,
): Promise<Document[]> {
    try {
        const response = await GetDocumentRequest(`${GetBaseUri()}/${patientId}/${facilityId}/${orgId}`, 'GET');
        return await response.json();
    } catch {
        return [];
    }
}

export async function SaveDocument(document: Document): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/`, 'POST', JSON.stringify(document));

    if (!result.ok) {
        throw new Error("Save Failed");
    }
}

export async function UpdateDocument(document: Document): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/`, 'PUT', JSON.stringify(document));

    if (!result.ok) {
        throw new Error("Save Failed");
    }
}

export async function DeleteDocument(documentId: string): Promise<void> {
    const result = await GetDocumentRequest(`${GetBaseUri()}/${documentId}/`, 'DELETE');

    if (!result.ok) {
        throw new Error("Delete Failed");
    }
}

export function GetBaseUri(): string {
    return `${_env_.REACT_APP_THERAPY_SCREENS_API_URL_BASE}/v1/documents`;
}

export function GetDocumentRequest(uri: string, method: string, body: string = null): Promise<Response> {
    return fetch(uri, {
        method: method,
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
    });
}
