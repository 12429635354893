import React, { useState } from "react";
import { DateQuestion } from "../../../model/DateQuestion";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../store/hooks";
import { updateAnswer } from "../../../store/slices/documentSlice";
import classes from "./dateQuestionComponent.module.scss";
import { QuestionComment } from "../questionComment/questionCommentComponent";
import dayjs, { Dayjs } from "dayjs";
import { Label } from "../../common/Label/Label";
import { CustomDatePicker } from "../../common/DatePicker/DatePicker";

import AppContext from "../../global";

function UpdateDateTimestamp(date: Date): Date {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
}

export const DateQuestionComponent: React.FC<{ question: DateQuestion }> = (
    props
) => {
    const dispatch = useAppDispatch();

    const [inputFieldValue, setInputFieldValue] = useState<Dayjs | null>(props.question.answer ? dayjs(props.question.answer) : null);

    function onChangeDate(date: Dayjs) {
        setInputFieldValue(date)

        if (date && date.isValid()) {
            dispatch(
                updateAnswer({
                    id: props.question.id,
                    answer: date.toISOString(),
                })
            );
        }
    }

    return (
        <Box className={classes.container}>
            <div className={classes.editorDiv}>
                <Label styleName={classes["label"]}>
                    {props.question.required
                        ? props.question.question + " *"
                        : props.question.question}
                </Label>

                {AppContext.isInPrintPreview ? (
                    <Label styleName={classes.print_label}>{inputFieldValue?.format("MM-DD-YYYY")}</Label>
                ) : (
                    <CustomDatePicker
                        dateValue={inputFieldValue}
                        onValueChanged={(newValue) => onChangeDate(newValue)}
                        minDate={props.question.min ? dayjs(props.question.min) : undefined}
                        maxDate={props.question.max ? dayjs(props.question.max) : undefined}
                        required={props.question.required}
                    />)}
            </div>

            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>
    );
};