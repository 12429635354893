import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem
} from "@mui/material";
import React from "react";
import { Button } from "../../../common/Button/Button";
import { Label } from "../../../common/Label/Label";
import CloseIcon from '@mui/icons-material/Close';
import classes from "./RequiredFieldsConfirmationDialog.module.scss"

export const RequiredFieldsConfirmationDialog: React.FC<{
    unansweredRequiredQuestions;
    isOpen;
    handleConfirm;
    handleCancel;
    isSaving?: boolean;
}> = (props) => {
    function confirm() {
        props.handleConfirm();
    }

    function cancel() {
        props.handleCancel();
    }

    return (
        <Dialog scroll="paper" fullWidth maxWidth="xs" open={props.isOpen}>
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        Please confirm
                    </Label>
                    <IconButton aria-label="close" onClick={cancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box className={classes.box}>
                    <Label styleName={classes.subheader}>
                        Are you sure you want to Save?
                    </Label>
                    <Label styleName={classes.subheader}>
                        Must select a value for:
                    </Label>

                    <Card className={classes.list}>
                        <List>
                            {props?.unansweredRequiredQuestions?.map(
                                (question) => {
                                    return (
                                        <React.Fragment key={question.id}>
                                            <Card
                                                className={classes.listview_card}
                                                variant="outlined"
                                                square
                                            >
                                                <ListItem className={classes.listViewItem} key={question.id}>
                                                    {question.question}
                                                </ListItem>
                                            </Card>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </List>
                    </Card>

                    <Label styleName={classes.footer}>
                        The Screen status will be Incomplete!
                    </Label>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button type="primary" onClick={confirm} disabled={!!props.isSaving}>
                    Yes, Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
