import React from "react";
import { QuestionComponent } from "../../questions/questionComponent";
import { SectionComponent } from "../sectionComponent";
import { TotalsComponent } from "../../totals/totalsComponent";
import { IsSection, IsTotal, Document } from "../../../model/Document";
import { Total } from "../../../model/Total";
import { Section } from "../../../model/Section";
import { Question } from "../../../model/Question";
import dayjs from "dayjs";

export type SectionPartPropsType = {
    part: Section | Question | Total;
    document: Document;
};

export const SectionPartComponent: React.FC<SectionPartPropsType> = (
    props: SectionPartPropsType
) => {
    var effFrom = dayjs(props.part.effectiveFrom), effThru = dayjs(props.part.effectiveThru), reqDt = dayjs(props.document.requestedDate);
    if (props.document.requestedDate && ((props.part.effectiveFrom && effFrom.isAfter(reqDt)) || (props.part.effectiveThru && effThru.isBefore(reqDt)))) {
        return <></>;
    }

    if (IsSection(props.part)) {
        return <SectionComponent section={props.part} document={props.document} />;
    }
    if (IsTotal(props.part)) {
        return <TotalsComponent totalSection={props.part} document={props.document} />;
    }
    return <QuestionComponent question={props.part} answerCategories={props.document.answerCategories} />;
};
