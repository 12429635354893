import { Box, Stack } from "@mui/material";
import { SourceDocument } from "../../../../model/SourceDocument";
import { AnswerCategory } from "../../../../model/AnswerCategory";
import { SelectQuestionOption } from "../../../../model/SelectQuestionOption";
import { Button } from "../../../common/Button/Button";
import { Label } from "../../../common/Label/Label";
import { useState } from "react";
import classes from "./EditScreenFormAnswersComponent.module.scss";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../../common/Dropdown/DropdownOption/DropdownOption";
import { useAppDispatch } from "../../../../store/hooks";
import { addAnswerCategory } from "../../../../store/slices/sourceDocumentSlice";
import { AnswerCategoryDialog } from "./dialogs/AnswerCategoryDialog";
import { FormResultsNumericGrid } from "./FormResultsNumericGridComponent";
import { FormResultsValueGrid } from "./FormResultsValueGridComponent";

type Props = {
    sourceDocument: SourceDocument;
};

export const EditScreenFormAnswersComponent: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const [selectedCat, setSelectedCat] = useState(() => {
        if (props.sourceDocument?.documentTemplate?.answerCategories !== null)
        {
            return props.sourceDocument?.documentTemplate?.answerCategories[0];
        }
        else
        {
            return { answerCategoryId: 0,
                answerCategoryName: "",
                answerCategoryType: "",
                options: []
            } as AnswerCategory;    
        }
    });
    const [answerCategories, setAnswerCategories] = useState(
        props.sourceDocument?.documentTemplate?.answerCategories?.map((cat) => ({
            ...cat,
            isNew: false,
            isEditableUntilSave: !props.sourceDocument.isInUse,
        })) ?? []
    );

    const [optionsList, setOptionsList] = useState(() => {
        if (props.sourceDocument?.documentTemplate?.answerCategories !== null)
        {
            return props.sourceDocument?.documentTemplate?.answerCategories[0].options.map(
            (opt) => ({
                ...opt,
                isNew: false,
                isEditableUntilSave: !props.sourceDocument.isInUse,
            })
        )
        }
        else {
            return [];
        }
    });

    const [isNewCatOpen, setIsNewCatOpen] = useState(false);

    function handleDropdownCategorySelect(answerText: string) {
        let selectedAnswerCat = answerCategories.find((c) => c.answerCategoryName === answerText);
        setSelectedCat(selectedAnswerCat);
        setOptionsList(
            answerCategories
                .find((r) => r.answerCategoryName === answerText)
                .options.map((opt) => ({
                    ...opt,
                    isNew: false,
                    isEditableUntilSave: !props.sourceDocument.isInUse,
                }))
        );
    }

    const handleInsertCategoryClick = () => {
        setIsNewCatOpen(true);
    };

    const handleSaveCategory = (catName: string, catType: string) => {
        setIsNewCatOpen(false);
        let newId = getAnswerCatMaxId() + 1;
        let newOptionList: SelectQuestionOption[] = [];
        setAnswerCategories((oldCats) => [
            ...oldCats,
            {
                isNew: false,
                isEditableUntilSave: true,
                answerCategoryId: newId,
                answerCategoryName: catName,
                answerCategoryType: catType,
                options: newOptionList,
            },
        ]);

        let newCategory = {
            answerCategory: {
                answerCategoryId: newId,
                answerCategoryName: catName,
                answerCategoryType: catType,
                options: newOptionList
            } as AnswerCategory
        };

        dispatch(addAnswerCategory(newCategory));
    };

    function getAnswerCatMaxId(): number {
        let catIdSort = [...answerCategories]?.sort((c) => c.answerCategoryId);
        return catIdSort[catIdSort?.length - 1]?.answerCategoryId ?? 0;
    }

    return (
        <Box
            sx={{
                "& .disabled": {
                    backgroundColor: "#E3E4E3",
                },
            }}
        >
            <Label styleName={classes.notes_header}>Filter By Category</Label>

            <Stack direction="row" spacing={2}>
                <Dropdown
                    sx={{ width: 250 }}
                    className={classes.select}
                    onChange={(event) => handleDropdownCategorySelect(event.target.value)}
                    value={selectedCat.answerCategoryName}
                >
                    {answerCategories.map((cat) => {
                        return (
                            <DropdownOption
                                key={cat.answerCategoryId}
                                value={cat.answerCategoryName}
                            >
                                {cat.answerCategoryName}
                            </DropdownOption>
                        );
                    })}
                </Dropdown>

                <div className={classes.insert_button}>
                    <Button onClick={handleInsertCategoryClick}>
                        Add Answer Category
                    </Button>
                </div>
            </Stack>

            <Stack>
                <Label styleName={classes.notes_header}>Notes:</Label>
                <Label styleName={classes.notes_label}>
                    1. To add a{" "}
                    <span className={classes.bold_notes}>New Answer Category</span>, Click
                    "Add Category" button, fill out the Category Box and click "Save."
                </Label>
                <Label styleName={classes.notes_label}>
                    2. To add a{" "}
                    <span className={classes.bold_notes}>New Answer Value</span> to
                    existing Category, select the Category from drop down, fill out the
                    Value, Value Score, Desc, and Effective From Date on the insert row.
                    Tap "Insert New Value" button.
                </Label>
                <Label styleName={classes.notes_label}>
                    3. Answers of Type "RANGE" should be in the format "1-10" and will be
                    used with "INCREMENT" Type rows.
                </Label>
            </Stack>

            {selectedCat.answerCategoryType === "VALUE" ? 
            <FormResultsValueGrid 
                selectOptions={optionsList}
                sourceDocInUse={props.sourceDocument.isInUse}
                selectedAnswerCategory={selectedCat}
                setOptionsList={setOptionsList}
            />
            : 
            <FormResultsNumericGrid 
                selectOptions={optionsList}
                sourceDocInUse={props.sourceDocument.isInUse}
                selectedAnswerCategory={selectedCat}
                setOptionsList={setOptionsList}
            />}

            <AnswerCategoryDialog
                isOpen={isNewCatOpen}
                handleSaveDialog={handleSaveCategory}
                handleCloseDialog={() => {
                    setIsNewCatOpen(false);
                }}
            />
        </Box>
    );
};
