import { Delete, Edit, Print, Save, Cancel } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material"
import classes from "./DocumentScreenTableRowActionItem.module.scss"
import { UserRights } from "../../../com_integration/models/UserRights";

type Props = {
    row,
    showDocument,
    deleteDocument,
    updateScreenReasonChanged,
    cancelEditDocument
    changePending,
    printDocument,
    userRights: UserRights
};

export const DocumentScreenTableRowActionItem: React.FC<Props> = (props: Props) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}>
            {props.changePending ?
                (
                    <Stack>
                        <Tooltip title={<span className={classes.toolTip}>Save</span>}>
                            <IconButton className={classes.icon} aria-label="edit" onClick={() => { props.updateScreenReasonChanged() }}>
                                <Save />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<span className={classes.toolTip}>Cancel</span>}>
                            <IconButton className={classes.icon} aria-label="edit" onClick={() => { props.cancelEditDocument() }}>
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ) : (
                    props.userRights?.edit && <Tooltip title={<span className={classes.toolTip}>Edit</span>} >
                        <IconButton className={classes.icon} aria-label="edit" onClick={() => { props.showDocument(props.row.id) }}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
            {props.userRights?.print && <Tooltip title={<span className={classes.toolTip}>Print</span>}>
                <IconButton className={classes.icon} aria-label="print" disabled={props.changePending} onClick={() => { props.printDocument(props.row.id) }}>
                    <Print />
                </IconButton>
            </Tooltip>}
            {props.userRights?.delete && <Tooltip title={<span className={classes.toolTip}>Delete</span>}>
                <IconButton className={classes.icon} aria-label="delete" onClick={() => { props.deleteDocument(props.row.id) }} disabled={props.changePending}>
                    <Delete />
                </IconButton>
            </Tooltip>}
        </Stack >
    )
}