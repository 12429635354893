import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { Label } from "../../../../common/Label/Label";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "../../../../common/Button/Button";
import { TextInput } from "../../../../common/TextInput/TextInput";
import { Dropdown } from "../../../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../../../common/Dropdown/DropdownOption/DropdownOption";
import classes from "./AnswerCategoryDialog.module.scss"

type Props = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    handleSaveDialog: (name: string, type: string) => void;
}

export const AnswerCategoryDialog: React.FC<Props> = (props) => {
    const [newCatName, setNewCatName] = useState('');
    const valueTypes = ["VALUE", "RANGE"];
    const [newCatType, setNewCatType] = useState(valueTypes[0]);

    function handleDropdown(valueType: string) {
        setNewCatType(valueType);
    }

    function cancel() {
        props.handleCloseDialog();
    }
    
    function saveName() {
        props.handleSaveDialog(newCatName, newCatType);
    }

    return (
        <Dialog scroll="paper" fullWidth maxWidth="xs" open={props.isOpen}>
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        Add Category
                    </Label>
                    <IconButton aria-label="close" onClick={cancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Stack className={classes.message_box}>
                    <Label styleName={classes.message}>New Category Name:</Label>
                    <TextInput onBlur={(e) => { setNewCatName(e.target.value) }} />
                </Stack>
                <Stack className={classes.message_box}>
                    <Label styleName={classes.message}>New Category Type:</Label>
                    <Dropdown
                        sx={{ width: 250 }}
                        className={classes.select}
                        onChange={(event) => handleDropdown(event.target.value)}
                        value={newCatType}
                    >
                        {valueTypes.map((typ) => {
                            return (
                                <DropdownOption
                                    key={typ}
                                    value={typ}
                                >
                                    {typ}
                                </DropdownOption>
                            );
                        })}
                    </Dropdown>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button type="primary" onClick={saveName}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}