import React, { useState } from "react";
import { Box } from "@mui/material";
import { updateAnswer } from "../../../store/slices/documentSlice";
import { useAppDispatch } from "../../../store/hooks";
import * as helperMethods from "../../helpers/helperMethods";
import { PhoneQuestion } from "../../../model/PhoneQuestion";
import classes from "./phoneQuestionComponent.module.scss";
import { QuestionComment } from "../questionComment/questionCommentComponent";
import { Label } from "../../common/Label/Label"
import { TextInput } from "../../common/TextInput/TextInput";

import AppContext from "../../global";

type Props = { question: PhoneQuestion };

const PhoneQuestionComponent: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const [textFieldValue, setTextFieldValue] = useState(props.question.answer ?? "");

    const isRequired = helperMethods.isRequired(props);
    const isAllowed = helperMethods.isAllowed(props);

    function handleOnBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        dispatch(
            updateAnswer({
                id: props.question.id,
                answer: textFieldValue,
            })
        );
    }

    function formatPhone(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        var textInput = e.target;
        var cursorPosition = textInput.selectionStart;
        var numericString = textInput.value.replace(/\D/g, '').substring(0, 15);

        // allow backspace over the ' Ext. '
        if (cursorPosition >= 14 && cursorPosition <= 19 && numericString.length === 10) return;

        // allow backspace over the '-'
        if (cursorPosition === 9 && numericString.length === 6) return;

        // allow backspace over the ') '
        if (cursorPosition === 5 && numericString.length === 3) return;
        if (cursorPosition === 4 && numericString.length === 3) return;

        var match = numericString.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,5})$/);
        if (match) {
            var newVal = '(' + match[1];
            newVal += match[2] ? ') ' + match[2] : '';
            newVal += match[3] ? '-' + match[3] : '';
            newVal += match[4] ? ' Ext. ' + match[4] : '';

            // put the cursor back in the right place
            var newPosition = cursorPosition;
            console.log(cursorPosition, textInput.value, textInput.value[cursorPosition - 1])
            if (cursorPosition > 0 && !isNaN(parseInt(textInput.value[cursorPosition - 1]))) {
                var preceedingnums = textInput.value.substring(0, cursorPosition).replace(/\D/g, '').substring(0, 15);
                var ixNew = 0, ixPrec = 0;
                while (ixPrec < preceedingnums.length && ixNew < newVal.length) {
                    if (ixPrec === preceedingnums.length - 1) {
                        // found cursor digit from old string in new string
                        newPosition = ixNew + 1;
                    }
                    if (preceedingnums[ixPrec] === newVal[ixNew]) {
                        ixNew++;
                        ixPrec++;
                    } else if (isNaN(parseInt(newVal[ixNew]))) {
                        ixNew++;
                    }
                }
            }

            setTextFieldValue(newVal);
            textInput.value = newVal;
            textInput.selectionEnd = newPosition;
        } else {
            setTextFieldValue("");
        }
    }

    function phoneKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key.length === 1 && !event.altKey && !event.ctrlKey && /[a-zA-Z\[\]`~\!\@\#\$\%\^\&\*\_=+\\\|\{\};:'",<.>\/\?]/.test(event.key)) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    return (
        <Box className={classes.container}>
            <div className={classes.editorDiv}>
                <Label styleName={classes.label}>
                    {props.question.required
                        ? props.question.question + " *"
                        : props.question.question}
                </Label>

                {AppContext.isInPrintPreview ? (
                    <Label styleName={classes.print_label}>{textFieldValue}</Label>
                ) : (
                    <TextInput
                        className={classes.inputField}
                        aria-required
                        error={isRequired}
                        type="tel"
                        placeholder="(000) 000-0000 Ext. 00000"
                        onKeyDown={(e) => phoneKeyPressed(e)}
                        label={isRequired ? "Required" : "" || isAllowed ? "Comment" : ""}
                        required={isRequired}
                        onChange={(e) => formatPhone(e)}
                        onBlur={handleOnBlur}
                        defaultValue={props.question.answer}
                        inputProps={{ maxLength: 25 }}
                    />
                )}
            </div>

            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>
    );
};

export default PhoneQuestionComponent;
