import React, { useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { DocumentBuilder } from "../../helpers/document/documentBuilder";
import { DocumentScreenTableScreenReasonItem } from "./DocumentScreenTableScreenReasonItem";
import { DocumentScreenTableRowActionItem } from "./DocumentScreenTableRowActionItem";
import classes from "./DocumentScreenTableRow.module.scss";
import { SourceDocument } from "../../../model/SourceDocument";
import { Label } from "../../common/Label/Label";
import { DocumentScreenTableScreenResultItem } from "./DocumentScreenTableScreenResultItem";
import { UserRights } from "../../../com_integration/models/UserRights";

type Props = {
    row,
    showDocument,
    deleteDocument,
    updateScreenReasonChanged,
    sourceDocuments: SourceDocument[],
    printDocument,
    userRights: UserRights
};

export const DocumentScreenTableRow: React.FC<Props> = (props: Props) => {
    const [changePending, setChangePending] = useState<Boolean>();
    const [initialScreenReason] = useState<string>(props.row.screenReason);
    const [selectedSource, setSelectedSource] = useState<string>(props.row.screenReason);

    function cancelEditDocument() {
        setSelectedSource(initialScreenReason);
        setChangePending(false);
    }

    function updateScreenReasonChanged() {
        let updatedSource = props.sourceDocuments.find((document) => document.documentTemplate.screenReason === selectedSource);

        let document = new DocumentBuilder()
            .setFacilityId(props.row.facilityId)
            .setFacilityName(props.row.facilityName)
            .setOrgId(props.row.orgId)
            .setPatientId(props.row.patientId)
            .setRequestDate(props.row.requestedDate)
            .setScreenDate(props.row.screenDate)
            .setSourceDocument(updatedSource)
            .setId(props.row.id)
            .setSourceDocumentId(updatedSource.id)
            .build();

        props.updateScreenReasonChanged(document);
    }

    return (
        <React.Fragment key={props.row.id}>
            <TableBody className={classes.table_row}>
                <TableRow >
                    <TableCell className={classes.table_cell} rowSpan={3} component="th" scope="row">{props.row?.screenDate}</TableCell>
                    <TableCell className={classes.table_cell} rowSpan={3}>
                        <DocumentScreenTableScreenReasonItem
                            sourceDocuments={props.sourceDocuments}
                            document={props.row}
                            setChangePending={setChangePending}
                            selectedSource={selectedSource}
                            setSelectedSource={setSelectedSource}
                            initialScreenReason={initialScreenReason}
                            canEdit={props.userRights?.edit}
                        />
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={3}>
                        <DocumentScreenTableScreenResultItem document={props.row} />
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={3}>{props.row?.screenDate}</TableCell>
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.OT ? (
                            <Label styleName={classes.bold_label}>
                                OT
                            </Label>
                        ) : <Label>
                            OT
                        </Label>}
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.OT ? (
                            <Label styleName={classes.bold_label}>
                                {props.row?.startOfCareDates?.otDate}
                            </Label>
                        ) : <Label>
                            {props.row?.startOfCareDates?.otDate}
                        </Label>}
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={3}>
                        <DocumentScreenTableRowActionItem
                            row={props.row}
                            showDocument={props.showDocument}
                            deleteDocument={props.deleteDocument}
                            cancelEditDocument={cancelEditDocument}
                            updateScreenReasonChanged={updateScreenReasonChanged}
                            changePending={changePending}
                            printDocument={props.printDocument}
                            userRights={props.userRights} />
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.PT ? (
                            <Label styleName={classes.bold_label}>
                                PT
                            </Label>
                        ) : <Label>
                            PT
                        </Label>}
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.PT ? (
                            <Label styleName={classes.bold_label}>
                                {props.row?.startOfCareDates?.ptDate}
                            </Label>
                        ) : <Label>
                            {props.row?.startOfCareDates?.ptDate}
                        </Label>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.ST ? (
                            <Label styleName={classes.bold_label}>
                                ST
                            </Label>
                        ) : <Label>
                            ST
                        </Label>}
                    </TableCell>
                    <TableCell className={classes.table_cell} rowSpan={1}>
                        {props.row?.disciplines?.ST ? (
                            <Label styleName={classes.bold_label}>
                                {props.row?.startOfCareDates?.stDate}
                            </Label>
                        ) : <Label>
                            {props.row?.startOfCareDates?.stDate}
                        </Label>}
                    </TableCell>
                </TableRow>
            </TableBody>
        </React.Fragment>
    )
}
