import { Box } from "@mui/material"
import React from "react"
import { Button } from "../common/Button/Button";
import classes from "./AddButtonHeader.module.scss"

export const AddButtonHeader: React.FC<{ setIsCreateDocumentDialogOpen }> = (props) => {

    function addNewScreen() {
        props.setIsCreateDocumentDialogOpen(true);
    }

    return (
        <Box className = {classes.buttonHeader}>
            <Button type="primary"onClick={addNewScreen}>
                Create
            </Button>
        </Box>
    )
}