import { useState } from "react";
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridActionsCellItem,
    GridEditInputCell,
    GridRowId,
    GridEventListener,
    GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import classes from "./FormResultsNumericGridComponent.module.scss";
import { CheckBox } from "../../../common/CheckBox/CheckBox";
import { Button } from "../../../common/Button/Button";
import { NumericQuestionOption } from "../../../../model/NumericQuestionOption";
import { useAppDispatch } from "../../../../store/hooks";
import {
    addSelectQuestionOption,
    updateSelectQuestionOption,
    removeSelectQuestionOption,
} from "../../../../store/slices/sourceDocumentSlice";
import { AnswerCategory } from "../../../../model/AnswerCategory";



type Props = {
    selectOptions;
    sourceDocInUse: boolean;
    selectedAnswerCategory: AnswerCategory;
    setOptionsList;
};

export const FormResultsNumericGrid: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const showSnackBar = (message: string) => {
        const action = (snackbarId) => (
            <IconButton
                className={classes.icon}
                aria-label="preview"
                onClick={() => {
                    closeSnackbar(snackbarId);
                }}
            >
                <CloseIcon style={{ fill: "white" }} />
            </IconButton>
        );

        enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            preventDuplicate: true,
            autoHideDuration: 9000,
            action: action,
        });
    };

    const [optionRowModesModel, setOptionRowModesModel] =
        useState<GridRowModesModel>({});
        
    const columns: GridColDef[] = [
        {
            field: "true",
            headerName: "In Use",
            type: "actions",
            width: 100,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <CheckBox
                    checked={
                        props.sourceDocInUse && !params.row.isEditableUntilSave
                    }
                    onChange={(e) => { }}
                    inputProps={{ "aria-label": "controlled" }}
                />
            ),
        },
        {
            field: "orderBy",
            headerName: "Order By",
            width: 100,
            editable: true,
            sortable: true,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "answerType",
            headerName: "Answer Type",
            type: "string",
            width: 130,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: "valueFrom",
            headerName: "Value From",
            type: "number",
            flex: 0.3,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: "left",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: "valueTo",
            headerName: "Value To",
            type: "number",
            flex: 0.3,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: "left",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: "valueScore",
            headerName: "Value Score",
            type: "number",
            width: 100,
            editable: true,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        min: 0,
                    }}
                />
            ),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            headerClassName: "super-app-theme--header",
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode =
                    optionRowModesModel[id]?.mode === GridRowModes.Edit;
                const isEditable = props.selectOptions.find(
                    (a) => a.optionId === id
                ).isEditableUntilSave;

                if (!isEditable) {
                    return [];
                }

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon className={classes.icon} />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveOptionClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon className={classes.icon} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelOptionClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon className={classes.icon} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditOptionClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon className={classes.icon} />}
                        label="Delete"
                        onClick={handleDeleteOptionClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleInsertOptionClick = () => {
        if (isAnyOptionCurrentlyInEditMode()) {
            showSnackBar("Finish editing the current row first.");
            return;
        }
        let newId = getOptionMaxId() + 1;
        let newOrder = getOptionMaxOrder() + 10;
        props.setOptionsList((existingOptions) => [...existingOptions,
        {
            isNew: true,
            isEditableUntilSave: true,
            optionId: newId,
            value: "New Value",
            valueScore: 0,
            answerType: props.selectedAnswerCategory.answerCategoryType,
            orderBy: newOrder
        }]);

        setOptionRowModesModel((existingModel) => ({
            ...existingModel,
            [newId]: { mode: GridRowModes.Edit, fieldToFocus: "value" },
        }));
    };

    function isAnyOptionCurrentlyInEditMode(): boolean {
        let isOptionInEditMode = false;

        props.selectOptions.some(c => {
            isOptionInEditMode = optionRowModesModel[c.optionId]?.mode === GridRowModes.Edit;
            return isOptionInEditMode;
        })

        return isOptionInEditMode;
    }

    function getOptionMaxId(): number {
        let optIdSort = [...props.selectOptions]?.sort((o) => o.optionId);
        return optIdSort[optIdSort?.length - 1]?.optionId ?? 0;
    }

    function getOptionMaxOrder(): number {
        let optIdSort = [...props.selectOptions]?.sort((o) => o.orderBy);
        return optIdSort[optIdSort?.length - 1]?.orderBy ?? 0;
    }

    const handleEditOptionClick = (id: GridRowId) => () => {
        setOptionRowModesModel({
            ...optionRowModesModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "value" },
        });
    };

    const handleSaveOptionClick = (id: GridRowId) => () => {
        setOptionRowModesModel({
            ...optionRowModesModel,
            [id]: { mode: GridRowModes.View },
        });
    };

    const handleDeleteOptionClick = (id: GridRowId) => () => {
        let deleteOption = {
            answerCategoryId: props.selectedAnswerCategory.answerCategoryId,
            selectQuestionOption: props.selectOptions.find(row => row.optionId === id) as NumericQuestionOption
        };
        props.setOptionsList(props.selectOptions?.filter((row) => row.optionId !== id));
        dispatch(removeSelectQuestionOption(deleteOption));
    };

    const handleCancelOptionClick = (id: GridRowId) => () => {
        setOptionRowModesModel({
            ...optionRowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = props.selectOptions?.find((option) => option.optionId === id);
        if (editedRow!.isNew) {
            props.setOptionsList(props.selectOptions?.filter((row) => row.optionId !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setOptionRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (
        params,
        event
    ) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processOptionUpdate = (newOption) => {
        newOption.value = newOption.value.trim();

        const updatedOption = { ...newOption, isNew: false };
        let option = props.selectOptions?.map((option) =>
            option.optionId === newOption.optionId ? updatedOption : option
        );

        if (validate(newOption)) {
            props.setOptionsList(option);
            closeSnackbar();

            let numericOpt = {
                selectQuestionOption: {
                    optionId: updatedOption.optionId,
                    value: updatedOption.value,
                    valueFrom: updatedOption.valueFrom,
                    valueTo: updatedOption.valueTo,
                    valueScore: updatedOption.valueScore,
                    answerType: updatedOption.answerType,
                    orderBy: updatedOption.orderBy,
                } as NumericQuestionOption,
                answerCategoryId: props.selectedAnswerCategory.answerCategoryId,
            };

            if (newOption.isNew) {
                dispatch(addSelectQuestionOption(numericOpt));
            } else {
                dispatch(updateSelectQuestionOption(numericOpt));
            }

            return updatedOption;
        }
    };

    function validate(option): boolean {
        let isValidated = true;

        if (!option?.value || option?.value.length < 1) {
            // hack empty check
            showSnackBar("Must have a non-zero length Value");
            isValidated = false;
        }

        return isValidated;
    };

    return (
        <>
            <div className={classes.insert_button}>
                <Button 
                    onClick={handleInsertOptionClick}
                    disabled={props.selectedAnswerCategory.answerCategoryType !== 'RANGE'}>
                Add Answer Value</Button>
            </div>

            {props.selectOptions?.length > 0 && (
            <DataGrid
            initialState={{sorting:{
                sortModel:[{field:'orderBy', sort:'asc'}],
                }}}
                scrollbarSize={15}
                className={classes.datagrid}
                getRowId={(row) => row?.optionId}
                rows={props.selectOptions}
                columns={columns}
                disableRowSelectionOnClick
                editMode="row"
                rowModesModel={optionRowModesModel}
                pageSizeOptions={[]}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processOptionUpdate}
                onRowEditStop={handleRowEditStop}
                isRowSelectable={(param) => false}
                isCellEditable={(param) => param.row.isEditableUntilSave}
                getRowClassName={(param) =>
                    props.sourceDocInUse && !param.row.isEditableUntilSave
                        ? "disabled"
                        : ""
                }
            />
            )}
        </>
    );
};