import React from "react";
import { Label } from "../Label/Label";
import classes from "./DisplayVersion.module.scss"

export type DisplayVersionProps = {
    version : string;
};

export const DisplayVersion: React.FC<DisplayVersionProps> = (
    props: DisplayVersionProps
) => {
    return (
        <div>
            <Label styleName={classes["label"]}>Therapy Screens version: {props.version || "0.0.0"}</Label>
        </div>
    );
};
