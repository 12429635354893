import React from "react";
import { Label } from "../../common/Label/Label";
import classes from "./subheaderTotalComponent.module.scss"

export type SubheaderTotalProps = {
    score: number;
    subheaderTitle: string;
};

export const SubheaderTotalComponent: React.FC<SubheaderTotalProps> = (
    props: SubheaderTotalProps
) => {
    return (
        <div
            style={{
                padding: "10px 5px",
                border: "solid 1px #ececec",
                borderTop: "none",
            }}
        >
            <Label styleName={classes["label"]} >{props.subheaderTitle || ""} Subtotal Score: {props.score || 0}</Label>
        </div>
    );
};
