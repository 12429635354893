import { CommentQuestion } from "../../../model/CommentQuestion";
import React from "react";
import { Label } from "../../common/Label/Label";
import classes from "./commentQuestionComponent.module.scss";

export type CommentQuestionPropsType = {
    question: CommentQuestion;
};

export const CommentQuestionComponent: React.FC<CommentQuestionPropsType> = (
    props: CommentQuestionPropsType
) => {
    return (
        <div
            style={{
                border: "solid 1px #ececec",
                padding: 5,
                borderTop: "none",
                overflowWrap: "anywhere",
            }}
        >
            <Label styleName={classes["label"]}>{props.question.question}</Label>
        </div>
    );
};
