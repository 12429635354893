import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
    updateAnswer,
} from "../../../store/slices/documentSlice";
import { useAppDispatch } from "../../../store/hooks";
import * as helperMethods from "../../helpers/helperMethods";
import { SingleselectQuestion } from "../../../model/SingleselectQuestion";
import { AnswerCategory } from "../../../model/AnswerCategory";
import classes from "./singleSelectComponent.module.scss";
import {
    QuestionComment,
} from "../questionComment/questionCommentComponent";

import { Label } from "../../common/Label/Label";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../common/Dropdown/DropdownOption/DropdownOption";

import AppContext from "../../global";
import { SelectQuestionOption } from "../../../model/SelectQuestionOption";

export const SingleSelectComponent: React.FC<{
    question: SingleselectQuestion;
    answerCategories: AnswerCategory[] | undefined;
}> = (props) => {
    const dispatch = useAppDispatch();
    const [selectFieldValue, setSelectFieldValue] = useState(
        props.question.answer ? props.question.answer : ""
    );

    const handleSelectOnChange = useCallback(
        (val: string) => {
            const o: SelectQuestionOption = !!p && p?.find(i=>i.value === val) 
                        ? p?.find( (i) => i.value === val)
                        : undefined;
            setSelectFieldValue(val);
            dispatch(
                updateAnswer({
                    id: props.question.id,
                    answer: val,
                    score: o.valueScore 
                })
            );
        },
        [props.question]
    );

    const currentScore = useMemo(() => {
        return helperMethods.getScore(props.question);
    }, [selectFieldValue]);

    let idx = 0;

    let p:SelectQuestionOption[] = !!props.question.answerCategory
        && !!props.answerCategories?.find(item => item.answerCategoryName === props.question.answerCategory)
        ? props.answerCategories.find(item => item.answerCategoryName === props.question.answerCategory).options
        : !!props.question
            ? props.question.options 
            : []

    return (

        <Box className={classes.container}>
            <div className={classes.editorDiv}>
                <Label styleName={classes.label}>
                    {props.question.required
                        ? props.question.question + " *"
                        : props.question.question}
                </Label>
                <div className={classes.selectGroup}>
                    {AppContext.isInPrintPreview ? (
                        <Label styleName={classes.print_label}>{selectFieldValue}</Label>
                    ) : (
                        <Dropdown
                            className={classes.select}
                            onChange={(event) => handleSelectOnChange(event.target.value)}
                            value={selectFieldValue}
                        >
                            {
                                p?.map((option) => {
                                    return (
                                        <DropdownOption key={idx++} value={option.value ?? option.Value}>
                                            {option.value ?? option.Value}
                                        </DropdownOption>
                                    );
                                })}
                        </Dropdown>
                    )}

                    {props.question.showScore && (
                        <div className={classes.score}>{currentScore}</div>
                    )}
                </div>
            </div>
            <div>
                {props.question.showNotes && (
                    <QuestionComment
                        noteText={props.question.note}
                        showNotes={props.question.showNotes}
                        noteRequired={props.question.noteRequired}
                        questionId={props.question.id}
                    />
                )}
            </div>
        </Box>

    );
};
