import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Document } from "../../../model/Document";
import { SourceDocument } from "../../../model/SourceDocument";
import React, { } from "react";
import { DocumentScreenTableRow } from "./DocumentScreenTableRow";
import classes from "./DocumentScreenTable.module.scss";
import { UserRights } from "../../../com_integration/models/UserRights";

type Props = {
    screens: Document[],
    showDocument,
    deleteDocument,
    sourceDocuments: SourceDocument[],
    updateScreenReasonChanged,
    printDocument,
    userRights: UserRights
};

export const DocumentScreenTable: React.FC<Props> = (props: Props) => {
    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table}
                aria-label="customized table">
                <TableHead>
                    <TableRow className={classes.table_head}>
                        <TableCell className={classes.cell}>Requested Date</TableCell>
                        <TableCell className={classes.cell}>Screen Reason</TableCell>
                        <TableCell className={classes.largeCell}>Screen&nbsp;Result</TableCell>
                        <TableCell className={classes.cell}>Screen&nbsp;Date</TableCell>
                        <TableCell className={classes.cell}></TableCell>
                        <TableCell className={classes.cell}>SOC</TableCell>
                        <TableCell className={classes.largeCell}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <>
                    {!!(props.screens) ? props.screens.map((row) => {
                        return (
                            <DocumentScreenTableRow
                                key={row.id}
                                row={row}
                                sourceDocuments={props.sourceDocuments}
                                showDocument={props.showDocument}
                                deleteDocument={props.deleteDocument}
                                updateScreenReasonChanged={props.updateScreenReasonChanged}
                                printDocument={props.printDocument}
                                userRights={props.userRights} />)
                    }) : <></>}
                </>
            </Table>
        </TableContainer>
    )
}