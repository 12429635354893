export const boxStyle = {
    mx: "auto",
    textAlign: "left",
    display: "inline-block",
    marginTop: 2,
    width: 700,
    overflowWrap: "anywhere"
}

export const textFieldStyle = {
    textAlign: "left",
    horizontalAlign: "left",
    marginTop: 2,
    width: 700
} as const

export const labelStyle = ({
    textAlign: "left" as "center",
    display: "block",
})

export const parentDivStyle = ({
    textAlign: "center" as "center",
    marginTop: 25,
    marginBottom: "25px"
})

export const dateInputStyle =
{
    textAlign: "left" as "left",
    horizontalAlign: "left" as "left",
    marginTop: 2,
    marginBottom: 2,
}

export const selectStyle =
{
    width: 150,
    marginTop: 2,
    marginBottom: 2,
    textAlign: "left" as "left",
    horizontalAlign: "left" as "left",
}

export const multiStyle =
{
    textAlign: "left" as "left",
    horizontalAlign: "left" as "left",
    marginTop: 1,
    marginBottom: 0
}

export const formGroupStyle =
{
    textAlign: "left" as "left",
    horizontalAlign: "left" as "left",
    width: 700
}

export const sectionTitleStyle =
{
    textAlign: "center" as "center",
    marginTop: 60,
}

export const sectionCaptionStyle =
{
    textAlign: "center" as "center",
    marginBottom: 60
}

export const mainHeaderStyle = ({
    position: "sticky" as "sticky",
    height: 90,
    width: "100%",
    top: 0,
    zIndex: 999,
    paddingTop: 25,
    paddingBottom: 25,
    display: "inline-block",
    textAlign: "center" as "center",
    alignSelf: "center" as "center",
    backgroundColor: "white",
    boxShadow: "0 2px 24px 0 rgb(0 0 0 / 15 %)"
})

export const documentPrimaryDivStyle = ({
    background: "lightgray",
    textAlign: "center" as "center",
})

export const documentSecondayDivStyle = ({
    background: "white",
    width: "1000px",
    display: "inline-block",
    textAlign: "center" as "center",
    marginTop: 50
})

export const headerLabelStyle = ({
    marginRight: 20
})

export const headerButtonStyle = ({
    height: 40,
    marginLeft: 10,
    marginRight: 10,
    width: 150,
    border: "1px solid darkGray",
    borderRadius: "20px"
})

export const headerInputStyle = ({
    height: 30,
    marginRight: 10
})

export const signOutButtonStyle = ({
    height: 40,
    marginRight: 10,
    alignSelf: "right" as "right",
    horizontalAlign: "right" as "right",
    border: "1px solid darkGray",
    borderRadius: "20px",
    width: 150,
})

export const headerDivStyle = ({
    display: "grid",
    gridTemplateColumns: "1fr 6fr 1fr "
})