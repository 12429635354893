import { AnswerCategory } from "../../model/AnswerCategory";
import { Question } from "../../model/Question";
import React from "react";
import { CommentQuestionComponent } from "./comment/commentQuestionComponent";
import { DateQuestionComponent } from "./dateQuestion/dateQuestionComponent";
import LargeTextQuestionComponent from "./largeTextQuestion/largeTextQuestionComponent";
import { MultiOptComponent } from "./multiSelect/multiSelectComponent";
import { NumericQuestionComponent } from "./numericQuestion/numericQuestionComponent";
import { SingleSelectComponent } from "./singleSelect/singleSelectComponent";
import TextQuestionComponent from "./textQuestion/textQuestionComponent";
import { CheckQuestionComponent } from "./checkQuestion/checkQuestionComponent";
import PhoneQuestionComponent from "./phoneQuestion/phoneQuestionComponent";

export type QuestionProps = {
    question: Question;
    answerCategories: AnswerCategory[] | undefined;
};

export const QuestionComponent = (props: QuestionProps) => {
    switch (props.question.kind) {
        case "LARGE_TEXT":
            return (
                <LargeTextQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
        case "TEXT":
            return (
                <TextQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
        case "DATE":
            return (
                <DateQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
        case "numeric":
            return (
                <NumericQuestionComponent
                    key={props.question.id}
                    question={props.question}
                    answerCategories={props.answerCategories}
                />
            );
        case "select":
            return (
                <SingleSelectComponent
                    key={props.question.id}
                    question={props.question}
                    answerCategories={props.answerCategories}
                />
            );
        case "multiselect":
            return (
                <MultiOptComponent key={props.question.id} question={props.question} />
            );
        case "COMMENT":
            return (
                <CommentQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
        case "check":
            return (
                <CheckQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
        case "PHONE_NUMBER":
            return (
                <PhoneQuestionComponent
                    key={props.question.id}
                    question={props.question}
                />
            );
    }
};
