import { Box, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"
import classes from "./EditScreenTabComponent.module.scss"
import { SourceDocument } from "../../../../model/SourceDocument"
import { EditScreenFormResultsComponent } from "./EditScreenFormResultsComponent"
import { EditScreenPartsEditorComponent } from "./maintenanceDocumentEditor/EditScreenPartsEditorComponent"
import { EditScreenFormAnswersComponent } from "./EditScreenFormAnswersComponent"

type Props = {
    sourceDocument: SourceDocument
}

export const EditScreenTabComponent: React.FC<Props> = (props) => {
    const [value, setValue] = useState('1');
    return (
        <TabContext value={value}>
            <Box>
                <TabList
                    className={classes.tabList}
                    onChange={(e, v) => { setValue(v) }}
                    TabIndicatorProps={{ sx: { display: 'none' } }}>
                    <Tab className={classes.tab} label="Questions" value='1' />
                    <Tab className={classes.tab} label="Form Answers" value='2' />
                    <Tab className={classes.tab} label="Form Results" value='3' />
                </TabList>
            </Box>
            <TabPanel className={classes.tabPanel} value='1'>
                <EditScreenPartsEditorComponent
                    sourceDocument={props.sourceDocument} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value='2'>
                <EditScreenFormAnswersComponent
                    sourceDocument={props.sourceDocument} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value='3'>
                <EditScreenFormResultsComponent />
            </TabPanel>
        </TabContext>
    )
}