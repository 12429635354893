import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { Label } from "../../../../common/Label/Label"
import { SourceDocument } from "../../../../../model/SourceDocument"
import { EditScreenTabComponent } from "../EditScreenTabComponent"
import { Button } from "../../../../common/Button/Button"
import classes from "./EditScreenDialog.module.scss"

import { useAppSelector } from "../../../../../store/hooks"
import { sourceDocumentSelector } from "../../../../../store/selectors/sourceDocumentSelectors"
import * as sourceDocumentApiHelper from "../../../../../repository/sourceDocument/sourceDocumentApiHelper"

type Props = {
    isOpen,
    sourceDocument: SourceDocument,
    close,
    saveFailed,
}

export const EditScreenDialog: React.FC<Props> = (props) => {

    let sourceDocument = useAppSelector(sourceDocumentSelector);

    const save = async () => {
        try {
            let documentToSave = sourceDocument;
            console.log(documentToSave);
            await sourceDocumentApiHelper.UpdateDocument(documentToSave);
        }
        catch (ex) {
            console.log("error " + ex.message)
            props.saveFailed(ex);
        }
    }

    const saveAndExit = async () => {
        await save();
        props.close();
    }

    const apply = async () => {
        await save();
    }

    const cancel = () => {
        props.close();
    }

    return (
        <>
            <Dialog
                scroll="paper"
                fullWidth
                maxWidth="lg"
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
                open={props.isOpen}>
                <DialogTitle>
                    <Label styleName={classes.title}>Editing {props.sourceDocument?.documentTemplate?.screenReason} Screen</Label>
                </DialogTitle>
                <DialogContent dividers>
                    <EditScreenTabComponent sourceDocument={props.sourceDocument} />
                </DialogContent >
                <DialogActions>
                    <Button type="secondary" onClick={cancel}>Cancel</Button>
                    <Button onClick={apply}>Apply</Button>
                    <Button onClick={saveAndExit}>Save and Close</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}