import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton, TextField
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../../common/Button/Button";
import { Label } from "../../../../../common/Label/Label";
import classes from "./AddPartDialog.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { Dropdown } from "../../../../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../../../../common/Dropdown/DropdownOption/DropdownOption";
import { Total } from "../../../../../../model/Total";
import { Section } from "../../../../../../model/Section";
import { Kinds } from "../../../../../../model/Kind";

type Props = {
    parent: Section | Total;
    parentsList: (Section | Total)[];
    isOpen: boolean;
    handleCreatePart: (parentID: number, kind: string, formatType: string) => void;
    handleCancel: () => void;
};

export const AddPartDialog: React.FC<Props> = (props: Props) => {
    const [parentValue, setParentValue] = useState(props?.parent?.id || 0);
    const [kindValue, setKindValue] = useState<{ kindKey: string, kind: string, formatType: string, Desc: string }>({ kindKey: "TEXT", kind: "TEXT", formatType: "", Desc: "Text" });
    const [isSubmitting, setIsSubmitting] = useState(false);

    function resetScreen() {
        setParentValue(props?.parent?.id || 0);
        setKindValue({ kindKey: "TEXT", kind: "TEXT", formatType: "", Desc: "Text" });
        setIsSubmitting(false);
    }

    function createDocument() {
        setIsSubmitting(true);
        if (!parentValue || !kindValue) {
            return;
        }

        props?.handleCreatePart(parentValue, kindValue.kind, kindValue.formatType);
        resetScreen();
    }

    function cancel() {
        props?.handleCancel();
        resetScreen();
    }

    function selectKind(kindKey: string) {
        let kind = Kinds.find(k => k.kindKey === kindKey);
        if (!!kind) {
            setKindValue(kind);
        }
    }

    return (
        <Dialog scroll="paper" fullWidth maxWidth="xs" open={props?.isOpen}>
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        Add
                    </Label>
                    <IconButton aria-label="close" onClick={cancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        width: "60%",
                    }}
                >
                    <FormControl error={!parentValue && isSubmitting}>
                        <div className={classes.selectGroup}>
                            Parent:
                            <Dropdown
                                className={classes.select}
                                onChange={(event) => setParentValue(event.target.value as number)}
                                value={parentValue}
                            >
                                {props?.parentsList?.map((option) =>
                                    <DropdownOption key={option.id} value={option.id}>
                                        {(option as Section)?.title || (option as Total)?.question}
                                    </DropdownOption>
                                )}
                            </Dropdown>
                        </div>
                        {!parentValue && isSubmitting && (
                            <FormHelperText>
                                Please enter Parent
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl error={!kindValue && isSubmitting}>
                        <div className={classes.selectGroup}>
                            Kind:
                            <Dropdown
                                className={classes.select}
                                onChange={(event) => selectKind(event.target.value)}
                                value={kindValue?.kindKey}
                            >
                                {Kinds.map((a) => (
                                    <DropdownOption key={a.kindKey} value={a.kindKey}>
                                        {a.Desc}
                                    </DropdownOption>
                                ))}
                            </Dropdown>
                        </div>
                        {!kindValue && isSubmitting && (
                            <FormHelperText>
                                Please enter Kind
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button type="primary" onClick={createDocument}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
